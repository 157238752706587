import { pipe, tap } from "wonka";
import { OperationResult, Exchange } from "urql";
import history from "src/utils/history";

const isLoggedOut = (o: OperationResult<unknown>): boolean => {
  const msg = (o.error && o.error.message.toLowerCase()) || "";

  return (
    // Unauthorized is returned during normal GQL errors
    msg.includes("unauthorized") ||
    // No UUID found is returned when requesting the currently authenticated
    // user's info when no cookie/jwt is present.
    msg.includes("no uuid found")
  );
};

const publicPaths = [
  "/login",
  "/register",
  "/reset-password/new",
  "/reset-password/reset",
];

const logoutExchange = ({ client, forward }: { client: any; forward: any }) => {
  return (operations$: any) => {
    return pipe(
      forward(operations$),
      tap((op) => {
        const o = op as OperationResult<unknown>;
        // eslint-disable-next-line
        const path = location.pathname;
        if (isLoggedOut(o) && publicPaths.indexOf(path) < 0) {
          // Encode search params for device login flow, which we need
          // to keep.
          const search = window.location.search && btoa(window.location.search);
          history.push(`/login?to=${path}&search=${search}`);
        }
      })
    );
  };
};

export default logoutExchange as Exchange;
