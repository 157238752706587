import { useProductionWorkspace } from "src/state/workspaces";
import { useMutation, useQuery } from "urql";
import { VercelApp } from "./types";

const vercelAppsGQL = `
query Workspace($workspaceId: ID!) {
  workspace(id: $workspaceId) {
    vercelApps {
      id
      workspaceID
      projectID
      path
    }
  }
}
`;

export const useVercelApps = () => {
  const workspace = useProductionWorkspace();

  return useQuery<{
    workspace?: {
      vercelApps?: VercelApp[];
    };
  }>({
    query: vercelAppsGQL,
    variables: { workspaceId: workspace.id },
    requestPolicy: "network-only",
  });
};

const createVercelAppGQL = `
mutation CreateVercelApp($input: CreateVercelAppInput!) {
  createVercelApp(input: $input) {
    success
  }
}
`;

interface CreateVercelAppInput {
  path?: string;
  projectID: string;
  workspaceID: string;
}

export const useCreateVercelApp = () => {
  const workspace = useProductionWorkspace();

  const [state, rawExecute] = useMutation<
    { success: boolean },
    { input: CreateVercelAppInput }
  >(createVercelAppGQL);

  const execute = (input: Omit<CreateVercelAppInput, "workspaceID">) => {
    return rawExecute({ input: { ...input, workspaceID: workspace.id } });
  };

  return [state, execute] as [typeof state, typeof execute];
};

const updateVercelAppGQL = `
mutation UpdateVercelApp($input: UpdateVercelAppInput!) {
  updateVercelApp(input: $input) {
    success
  }
}
`;

interface UpdateVercelAppInput {
  projectID: string;
  path: string;
}

export const useUpdateVercelApp = () => {
  const [state, rawExecute] = useMutation<
    { success: boolean },
    { input: UpdateVercelAppInput }
  >(updateVercelAppGQL);

  const execute = (input: UpdateVercelAppInput) => rawExecute({ input });

  return [state, execute] as [typeof state, typeof execute];
};

const removeVercelAppGQL = `
mutation RemoveVercelApp($input: RemoveVercelAppInput!) {
  removeVercelApp(input: $input) {
    success
  }
}
`;

interface RemoveVercelAppInput {
  projectID: string;
  workspaceID: string;
}

export const useRemoveVercelApp = () => {
  const workspace = useProductionWorkspace();

  const [state, rawExecute] = useMutation<
    { success: boolean },
    { input: RemoveVercelAppInput }
  >(removeVercelAppGQL);

  const execute = (input: Omit<RemoveVercelAppInput, "workspaceID">) => {
    return rawExecute({ input: { ...input, workspaceID: workspace.id } });
  };

  return [state, execute] as [typeof state, typeof execute];
};
