import React, { useState } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { useFormState } from "react-use-form-state";
import Box from "src/shared/Box";
import Button, { ButtonGroup } from "src/shared/Button";
import useToast from "src/shared/Toast";
import { useCreateWebhook } from "./query";

type Props = {
  onComplete: () => void;
};

const NewWebhook: React.FC<Props> = ({ onComplete }) => {
  const { push } = useToast();
  const [saving, setSaving] = useState(false);
  const [form, { text }] = useFormState();
  const execute = useCreateWebhook();

  const onSubmit = async () => {
    if (saving) {
      return;
    }

    setSaving(true);
    const result = await execute({
      input: {
        name: form.values.name,
        url: form.values.url,
        // TODO: Allow the user to select which events to receive
        allEvents: true,
        events: [],
      },
    });
    setSaving(false);

    if (result.error || !result.data) {
      push({
        type: "error",
        message: result.error ? result.error.toString() : "Please try again",
      });
      return;
    }

    push({
      type: "success",
      message: "Created webhook",
    });

    onComplete();
  };

  return (
    <Box title="Create a webhook">
      <Form onSubmit={onSubmit}>
        <p>
          Receive HTTP requests whenever an event occurs within our system.
          <br />
          You can use this to receive notifications of new survey requests and
          responses.
        </p>
        <label>
          Name
          <input {...text("name")} placeholder="Name" />
        </label>
        <label>
          URL
          <input {...text("url")} placeholder="URL" />
        </label>

        <ButtonGroup right style={{ marginTop: "20px" }}>
          <Button
            kind="primary"
            type="submit"
            onClick={onSubmit}
            disabled={saving}
          >
            Create
          </Button>
        </ButtonGroup>
      </Form>
    </Box>
  );
};

const coreCss = css`
  min-width: 600px;

  p {
    text-align: center;
    margin: 0 auto 2rem;
    opacity: 0.7;
    max-width: 90%;
    font-size: 14px;
  }
`;

const Form = styled.form`
  ${coreCss}
`;

export default NewWebhook;
