export const colors = {
  green: "#CEECCE",
  yellow: "#eae985",
  red: "#ff7b7b",
  lightBlue: "#6192ae",
  darkBlue: "#193c50",
  bg: "#fffdfa",

  border: "#EFEEEA",
};

export default colors;
