import styled from "@emotion/styled";

type Props = SpinnerProps & {
  stretch?: boolean;
  text?: string;
};

export const Loading = ({ text, stretch, size }: Props) => {
  return (
    <Wrapper css={[stretch && stretchCSS]}>
      <Spinner aria-hidden size={size} />
      {text ? <p>{text}</p> : null}
    </Wrapper>
  );
};

type SpinnerProps = {
  size?: number;
};

export default Loading;

const stretchCSS = `
  flex: 1;
  align-self: stretch;
  justify-self: stretch;
`;

const Wrapper = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 0.25rem 0;
    opacity: 0.5;
  }

  > div {
    display: block;
    background: url(/assets/loader.png) no-repeat center center;
    background-size: contain;
    animation: spin 1.25s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Spinner = styled.div<SpinnerProps>`
  display: block;
  background: url(/assets/loader.png) no-repeat center center;
  background-size: contain;
  animation: spin 1.25s linear infinite;
  width: ${(props) => props.size || 40}px;
  height: ${(props) => props.size || 40}px;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;
