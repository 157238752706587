import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormState } from "react-use-form-state";
import Box from "src/shared/Box";
import Logo from "src/shared/Icons/Logo";
import styled from "@emotion/styled";
import Button from "src/shared/Button";
import useToast from "src/shared/Toast";
// local
import BG from "./BG";

export default () => {
  const [loading, setLoading] = useState(false);
  const [formState, { email }] = useFormState();
  const { push } = useToast();

  const disabled = !formState.values.email;

  const handleSubmit = async (e: React.SyntheticEvent) => {
    setLoading(true);
    e.preventDefault();

    const result = await fetch(
      `${
        process.env.REACT_APP_API_HOST
      }/v1/reset-password/new?email=${encodeURIComponent(
        formState.values.email
      )}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
      }
    );

    setLoading(false);
    if (result.status !== 201) {
      push({
        type: "error",
        message: "There was an error resetting your password",
      });
      return;
    }
    push({
      type: "success",
      message:
        "We'll send you a link to reset your password if that email was found.",
    });
  };

  return (
    <BG>
      <Logo width={100} fill="#fff" style={{ margin: "20px 0" }} />
      <Box>
        <Heading>Reset password</Heading>

        <Form onSubmit={handleSubmit}>
          <input {...email("email")} placeholder="Your email" />
          <Button
            type="submit"
            size="large"
            disabled={disabled}
            loading={loading}
            kind={disabled ? undefined : "primary"}
          >
            Reset password
          </Button>
          <BottomLink to="/login">Back to login</BottomLink>
        </Form>
      </Box>
    </BG>
  );
};

const Form = styled.form`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  button {
    width: 100%;
    text-align: center;
    display: block;
    margin-top: 30px;
  }
`;

const Heading = styled.p`
  margin: 0;
  padding: 0 0 30px;
  text-align: center;
`;

const BottomLink = styled(Link)`
  margin: 16px 0 0;
  background: transparent;
  font-size: 12px;
  text-align: center;
  display: block;
`;
