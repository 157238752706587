import React, { useEffect } from "react";
import { useLocation, useRouteMatch, matchPath } from "react-router-dom";

import useSelf from "src/state/self";
import {
  REACT_APP_INGEST_API_HOST,
  REACT_APP_INNGEST_SOURCE_KEY_ANALYTICS,
} from "src/utils/env";
import { capFirst } from "src/utils/strings";

type MatchParams = {
  [key: string]: string;
};

const PATHS: { [key: string]: string } = {
  "/": "Home",
  "/functions": "Function List",
  "/functions/new": "Function New",
  "/functions/new/editor": "Function IDE New",
  "/functions/:id/edit": "Function IDE Edit",
  "/functions/:id/:subpath?": "Function Detail",
  "/functions/:id/runs/:runID": "Function Run",
  "/events": "Event List",
  "/events/e/:id/:subpath?": "Event Detail",
  "/events/id/:id": "Event Inspect",
  "/sources": "Source List",
  "/sources/new(#.*)": "Source Catalog Expand",
  "/sources/new": "Source Catalog",
  "/sources/:id": "Source Edit",
  "/settings/:subpath?": "Settings",
};

const getViewName = (pathname: string, hash: string): string => {
  // Join both together to match against the full path
  const path = hash ? pathname + hash : pathname;
  const m = Object.keys(PATHS).find(
    (p) => matchPath(path, { path: p })?.isExact
  );
  const viewName = m ? PATHS[m] : "";
  const match = matchPath<MatchParams>(path, { path: m });
  if (match && match.params?.subpath) {
    return `${viewName} ${capFirst(match.params.subpath)}`;
  }
  if (hash) {
    return `${viewName} ${capFirst(hash.substring(1))}`;
  }
  if (viewName) {
    return viewName;
  }
  return capFirst(pathname.substring(1).split("/")[0]);
};

const AnalyticsWrapper: React.FC = ({ children }) => {
  const location = useLocation();
  const { data } = useSelf();

  if (!window.hasOwnProperty("Inngest")) {
    console.error("Inngest SDK not properly loaded");
  }

  useEffect(() => {
    if (!data || !data.session) {
      return;
    }
    if (!REACT_APP_INNGEST_SOURCE_KEY_ANALYTICS) {
      console.error(
        "Analytics source key not set: REACT_APP_INNGEST_SOURCE_KEY_ANALYTICS"
      );
      return;
    }
    const viewName = getViewName(location.pathname, location.hash);
    Inngest.event(
      {
        name: "app/page.viewed",
        data: {
          title: viewName,
          isTestMode: !!location.pathname.match(/^\/test\//),
        },
        user: {
          external_id: data.session.user.id,
          email: data.session.user.email,
          name: data.session.user.name,
          account_id: data.account?.id,
          account_name: data.account?.name || data.account?.billingEmail,
        },
      },
      {
        key: REACT_APP_INNGEST_SOURCE_KEY_ANALYTICS,
        host: REACT_APP_INGEST_API_HOST,
      }
    );
  }, [location, data]);
  return <>{children}</>;
};

export default AnalyticsWrapper;
