import { useMutation } from "urql";

export const useUpdateUserAccount = () => {
  const [, execute] = useMutation(`
    mutation UpdateUserAccount($user: UpdateUser!, $acct: UpdateAccount!) {
      user: updateUser(input: $user) {
        id email name
      }
      account: updateAccount(input: $acct) {
        id name
      }
    }
  `);

  return execute;
};
