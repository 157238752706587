import { css, SerializedStyles } from "@emotion/core";
import styled from "@emotion/styled";
import React from "react";
import { useToast } from "src/shared/Toast";

type Props = React.HTMLAttributes<any> & {
  kind?: KindStrings;
  kinds?: Array<KindStrings | undefined | false>;
  copyable?: boolean;
  copyData?: string;
  keepCase?: boolean;
  hover?: boolean;
};

enum Kinds {
  default,
  transparent,
  black,
  grey,

  success,
  green,
  error,
  red,

  identifier,
}

type KindStrings = keyof typeof Kinds;

const C: React.FC<Props> = (props) => {
  const { copyable, children, ...rest } = props;
  const { push } = useToast();
  const ref = React.useRef<HTMLSpanElement>(null);

  let applied = (props.kinds || [props.kind || "default"])
    .filter(Boolean)
    .map((kind) => kinds[kind as KindStrings])
    .filter(Boolean);

  copyable && applied.push(copyCSS);
  props.keepCase && applied.push(keepCaseCSS);
  props.hover && applied.push(hoverCSS);

  const copy = async () => {
    const span = ref.current as HTMLSpanElement;
    try {
      await navigator?.clipboard?.writeText(props.copyData || span.innerText);
      push({ message: "Copied to clipboard", type: "default" });
    } catch (e) {}
  };

  const onClick = (e: React.MouseEvent) => {
    if (copyable) {
      copy();
    }

    return props.onClick && props.onClick(e);
  };

  return (
    <Tag className="tag" ref={ref} css={[applied]} {...rest} onClick={onClick}>
      {children}
    </Tag>
  );
};

export default C;

const Tag = styled.span`
  --padding-vertical: 4.5px;
  --padding-horizontal: 8px;

  display: inline-block;
  padding: var(--padding-vertical) var(--padding-horizontal);
  height: calc(1em + var(--padding-vertical) * 2);
  border-radius: var(--border-radius);

  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1em;

  text-overflow: ellipsis;
  overflow: hidden;

  span {
    display: inline-block;
    vertical-align: top;
    padding: calc(var(--padding-vertical) - 1px) var(--padding-horizontal);
    position: relative;
    top: calc(-1 * var(--padding-vertical) + 1px);
    bottom: calc(-1 * var(--padding-vertical) + 1px);
    left: calc(-1 * var(--padding-horizontal) + 1px);
    background-color: var(--color-black);
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }

  & + & {
    margin-left: 0.3em;
  }

  background: var(--tag-bg);
  color: var(--tag-text);
`;

const copyCSS = css`
  cursor: pointer;
`;

// Synonym of default
const greyCSS = css``;

const blackCSS = css`
  background: var(--color-black);
  border: 1px solid var(--color-gray-900);
  color: var(--color-gray-200);
`;

const greenCSS = css`
  background: var(--color-green);
  color: var(--color-dark-green);
`;

const redCSS = css`
  background: var(--color-red);
  color: var(--color-white);
`;

const transparentCSS = css`
  background: transparent;
  border: 1px solid var(--border-color-focus);
`;

const identifierCSS = css`
  ${transparentCSS}
  color: var(--color-dim);
  font-family: monospace;
  font-size: 10px;
`;

const hoverCSS = css`
  cursor: pointer;
  box-shadow: none;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 20px 80px rgba(0, 0, 0, 0.3), 0 10px 20px rgba(0, 0, 0, 0.6);
    transform: translateY(-1px);
  }
`;

const keepCaseCSS = css`
  text-transform: none;
`;

const kinds: { [key in KindStrings]: SerializedStyles } = {
  default: greyCSS,

  black: blackCSS,
  green: greenCSS,
  red: redCSS,
  transparent: transparentCSS,
  success: greenCSS,
  error: redCSS,

  identifier: identifierCSS,
  grey: greyCSS,
};
