// Source coolicons.cool
import React from "react";
import type { IconProps } from "./props";

const UserCircle = ({ size = "16", fill = "currentColor" }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4777 12.8882C10.6233 11.9339 9.38174 11.3333 7.99997 11.3333C6.6182 11.3333 5.3767 11.9339 4.52222 12.8882"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 9.33331C9.10457 9.33331 10 8.43788 10 7.33331C10 6.22874 9.10457 5.33331 8 5.33331C6.89543 5.33331 6 6.22874 6 7.33331C6 8.43788 6.89543 9.33331 8 9.33331Z"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserCircle;
