const stripSlash = (url: string) =>
  url.endsWith("/") ? url.substr(0, url.length - 1) : url;

export const INGEST_API = stripSlash(
  process.env.REACT_APP_INGEST_HOST || "https://inn.gs"
);
export const API = stripSlash(
  process.env.REACT_APP_API_HOST || "https://api.inngest.com"
);

export const POST_LOGIN_REDIRECT_KEY = "post_login_redirect";
