import React from "react";
import { SelfProvider } from "src/state/self";

type Props = {
  children: React.ReactNode;
};

// Wrapper represents a top-level wrapper which fetches user information
// for the currently authenticated user.
export default ({ children }: Props) => {
  return <SelfProvider>{children}</SelfProvider>;
};
