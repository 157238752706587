import React, { useState } from "react";
import styled from "@emotion/styled";
import { useFormState } from "react-use-form-state";
import Box from "src/shared/Box";
import Button, { ButtonGroup } from "src/shared/Button";
import useToast from "src/shared/Toast";
import { useAddUser } from "./query";

type Props = {
  onComplete: () => void;
};

const NewUser: React.FC<Props> = ({ onComplete }) => {
  const { push } = useToast();
  const [saving, setSaving] = useState(false);
  const [form, { text, email }] = useFormState();
  const execute = useAddUser();

  const onSubmit = async () => {
    if (saving) {
      return;
    }

    setSaving(true);
    const result = await execute({ input: form.values });
    setSaving(false);

    if (result.error) {
      push({ type: "error", message: result.error.toString() });
      return;
    }

    push({
      type: "success",
      message: `Invited ${form.values.name || form.values.email}`,
    });
    onComplete();
  };

  return (
    <Box title="Add a user">
      <Form onSubmit={onSubmit}>
        <p>
          Invite a user to your account.
          <br />
          They'll be able to log in and manage your workspaces.
        </p>
        <label>
          Name
          <input {...text("name")} placeholder="Name" />
        </label>

        <label>
          Email address
          <input {...email("email")} />
        </label>

        <ButtonGroup right style={{ marginTop: "20px" }}>
          <Button
            kind="primary"
            type="submit"
            onClick={onSubmit}
            disabled={saving}
          >
            Add user
          </Button>
        </ButtonGroup>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  min-width: 500px;

  p {
    text-align: center;
    margin: 0 auto 2rem;
    opacity: 0.7;
    max-width: 80%;
    font-size: 14px;
  }
`;

export default NewUser;
