import React from "react";
import styled from "@emotion/styled";
import Button, { ButtonGroup } from "src/shared/Button";
import Box from "src/shared/Box";
import colors from "src/shared/color";

type Props = {
  text: React.ReactNode;
  title: string;
  style?: object;
};

const UpgradeBox: React.FC<Props> = ({ text, title, style }) => {
  return (
    <Wrapper style={style}>
      <h2>{title}</h2>
      <p>{text}</p>

      <ButtonGroup center>
        <Button kind="link" link="/admin/billing">
          Check out the available plans
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #eee;
  border-top: 2px solid ${colors.green};

  h2 {
    margin: 0 0 5px;
  }
  p {
    opacity: 0.7;
    margin: 0 0 15px;
    font-size: 14px;
  }
`;

export default UpgradeBox;
