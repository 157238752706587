import React, { useEffect, useState } from "react";
import { useDebounce } from "src/utils/debounce";
import styled from "@emotion/styled";
import { Popover } from "react-tiny-popover";
import SearchIcon from "src/shared/Icons/Search";

export type ResultProps = {
  selected: boolean;
  className?: string;
  onClick: () => void;
};

type ResultGroup = {
  title: string;
  results: Result[];
};

type Result = {
  value: string;
  data: any;
  render?: (props: ResultProps) => React.ReactNode;
  onSelect?: () => void;
};

function isResult(item: ResultGroup | Result): item is Result {
  return (item as Result).value !== undefined;
}

type Props = {
  ref?: React.Ref<HTMLInputElement>;
  results?: Array<Result | ResultGroup>;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  onChange: (value: string) => void;
  onSelect: (item: any) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  icon?: boolean;
  loading?: string; // text to show when loading

  style?: any;
  className?: string;
  css?: any;

  resultsCSS?: any;
};

const SearchInput: React.FC<Props> = React.forwardRef(
  (props, ref: React.Ref<HTMLInputElement>) => {
    const [index, setIndex] = useState(0);
    const [visible, setVisible] = useState(true);
    const [value, setValue] = useState(props.defaultValue || "");

    // forceHide is a flag set when an item is selected.  On select, we want to hide
    // the autocomplete dropdown.  We can't do this as by default autocomplete is visible
    // with any input.  This field is set to false on change and set to true only when
    // an itme is selected.
    const [forceHide, setForceHide] = useState(false);

    const onChangeDebounced = useDebounce(props.onChange);

    const onUpdateValue = (value: string) => {
      setValue(value);
      onChangeDebounced(value);
    };

    // onSelect is called when an item is selected via return key or mouse.
    const onSelect = (selected: any) => {
      selected && props.onSelect && props.onSelect(selected);
      // Set local state.
      selected && setValue(selected.value);
      setForceHide(true);
      // Ensure that we trigger "onchange" for this, so the parent component
      // can capture its state
      onChangeDebounced(selected.value);
    };

    useEffect(() => {
      setVisible(value !== props.defaultValue && value !== "");
    }, [value]);

    // Flatten all items for selecting the index.
    const items = (props.results || [])
      .map((i) => (isResult(i) ? i : i.results))
      .flat();

    const renderItem = (item: Result | ResultGroup, n: number) => {
      if (!isResult(item)) {
        // This is a group. Render a title and a group.
        return (
          <div className="group">
            <div className="group-title">{item.title}</div>
            {item.results.map(renderItem)}
          </div>
        );
      }

      const className = items.indexOf(item) === index ? "selected" : "";
      const selected = items.indexOf(item) === index;

      if (item.render) {
        return item.render({
          selected,
          className: `result ${className || ""}`,
          onClick: () => {
            item.onSelect ? item.onSelect() : onSelect(item);
          },
        });
      }

      return (
        <div
          key={item.value}
          className={`result ${className || ""}`}
          onClick={() => {
            item.onSelect ? item.onSelect() : onSelect(item);
          }}
        >
          <span>{item.data}</span>
        </div>
      );
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          setIndex(Math.min(items.length - 1, index + 1));
          break;
        case "ArrowUp":
          e.preventDefault();
          setIndex(Math.max(index - 1, 0));
          break;
        case "Enter": {
          if (forceHide || !visible) {
            // let enter submit forms etc. if we're not showing autocomplete results
            return;
          }
          e.preventDefault();
          const selected = items[index];
          selected.onSelect ? selected.onSelect() : onSelect(selected);
          setVisible(false);
          break;
        }
        case "Escape":
          onUpdateValue("");
          setVisible(false);
          break;
        case "Tab":
          // Don't do anything including resetting force hide.  May be tabbing to
          // next input.
          break;
        case "Shift":
          // Don't do anything including resetting force hide. May be shift-tabbing
          // to previous input.
          break;
        default:
          setForceHide(false);
      }
    };

    return (
      <SearchWrapper
        style={props.style}
        className={props.className}
        css={props.css}
      >
        {props.icon !== false && <SearchIcon style={{ opacity: 0.5 }} />}
        <Popover
          isOpen={visible && !forceHide}
          positions={["bottom", "right"]}
          align="start"
          padding={5}
          content={
            <Results css={props.resultsCSS}>
              {(props.results || []).map(renderItem)}
              {(!props.results || props.results.length === 0) && (
                <div>
                  <small className="none">
                    {props.loading || "No results"}
                  </small>
                </div>
              )}
            </Results>
          }
          containerStyle={{ boxShadow: "0 4px 14px rgba(0, 0, 0, 0.12)" }}
        >
          <input
            ref={ref}
            placeholder={props.placeholder || "Search..."}
            value={props.value !== undefined ? props.value : value}
            onChange={(e) => onUpdateValue(e.target.value)}
            onBlur={() => {
              window.setTimeout(() => setVisible && setVisible(false), 300);
              props.onBlur && props.onBlur();
            }}
            onFocus={() => {
              setIndex(0);
              value !== "" && setVisible(true);
              props.onFocus && props.onFocus();
            }}
            onKeyDown={onKeyDown}
          />
        </Popover>
      </SearchWrapper>
    );
  }
);

export default SearchInput;

const SearchWrapper = styled.div`
  flex: 1;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    margin: -6px 0 0 10px;
    pointer-events: none;
  }

  input {
    box-shadow: none;
    margin: 0;
  }

  border-radius: 2px;
  transition: all 0.3s;
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03);
  width: 100%;

  svg + input {
    padding-left: 32px;
  }
`;

const Results = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--menu-bg);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 4px;
  border-radius: 3px;
  min-width: 500px;
  overflow-y: auto;

  svg {
    margin: 0 8px 0 4px;
  }

  .result.selected {
    background: var(--menu-active);

    & svg path {
      fill: var(--color);
    }

    &,
    & b {
      color: var(--color);
    }
  }

  .result {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding: 3px 5px;
    border-radius: 3px;
    margin: 1px 0;
    color: var(--color-dim);
    cursor: pointer;

    b {
      color: var(--color);
    }
  }
`;
