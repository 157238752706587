import { useQuery, useMutation } from "urql";
import { CombinedError } from "@urql/core";
import { DateTime } from "luxon";
import { Plan, PaymentMethod } from "./types";

export const billingDataGQL = `
  query BillingQuery {
    account {
      billingEmail
      plan {
        id
        name
        amount
        billingPeriod,
        features
      }

      paymentMethods {
        brand
        last4
        expMonth
        expYear
        createdAt
        default
      }
    }

    plans {
      id
      name
      amount
      billingPeriod
      features
    }

  }
`;

export type BillingData = {
  account: {
    billingEmail: string;
    plan: Plan;
    paymentMethods: PaymentMethod[] | null;
  };
  plans: Array<Plan>;
};

export const useBillingData = (
  accountCreatedAt: string
): [
  boolean,
  CombinedError | undefined,
  BillingData | undefined,
  () => void | undefined
] => {
  const [{ fetching, error, data }, refetch] = useQuery<BillingData>({
    query: billingDataGQL,
    variables: {
      month: DateTime.utc().startOf("month"),
      start: accountCreatedAt,
    },
    requestPolicy: "cache-and-network",
  });
  return [fetching, error, data, refetch];
};

export const updatePaymentMethodGql = `
  mutation UpdatePM($token: String!) {
    updatePaymentMethod(token: $token) {
      last4
      expMonth
      expYear
    }
  }
`;

export const useUpdatePM = () => {
  const [, updatePMRequest] = useMutation(updatePaymentMethodGql);
  return updatePMRequest;
};

export const upgradeGql = `
  mutation UpgradePlan($id: ID!) {
    updatePlan(to: $id) {
      id
      plan {
        id
        name
        amount
        billingPeriod
        features
      }
    }
  }
`;

export const useUpgrade = () => {
  const [, upgradeRequest] = useMutation(upgradeGql);
  return upgradeRequest;
};
