import React from "react";
import styled from "@emotion/styled";
import FeedbackIcon from "./Icons/Feedback";
import { useFeedback } from "src/state/feedback";
import Button, { ButtonGroup } from "./Button";

export const Feedback = () => {
  const { content, setContent, visible, setVisible, sent, send } =
    useFeedback();

  return (
    <FeedbackWrapper className={visible ? "active" : ""}>
      <button
        onClick={() => {
          setVisible(!visible);
        }}
      >
        <FeedbackIcon size="20" />
        Feedback?
      </button>
      <div className="feedback-window">
        <div className="form">
          What's your feedback?
          <br />
          <small>
            We're open to everything: requests for help, features, issues,
            ideas, memes... <b>We're very grateful for your thoughts</b>!
          </small>
          <textarea
            rows={6}
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
          <ButtonGroup right style={{ marginTop: ".5rem" }}>
            <Button kind="primary" onClick={() => send()} wide>
              Send
            </Button>
          </ButtonGroup>
        </div>
        <div className={`feedback-result ${sent ? "sent" : ""}`}>
          <h3>Thanks for your feedback!</h3>
          <p>
            We really appreciate it - we know you didn't have to spend the time
            on us.
          </p>
        </div>
      </div>
    </FeedbackWrapper>
  );
};

export default Feedback;

const FeedbackWrapper = styled.div`
  display: flex;
  align-self: stretch;
  align-items: stretch;
  position: relative;

  > button {
    transition: all 0.3s;
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    color: var(--color);
    padding: 1px 1rem 0;
    border-left: 1px solid var(--bg);
    border-right: 1px solid var(--bg);
    opacity: 0.75;

    &:hover {
      opacity: 1;
      background: var(--menu-active-bg);
    }

    svg {
      margin: 0 1rem 0 0;
    }
  }

  .feedback-window {
    position: absolute;
    width: min(100vw, 650px);
    top: 100%;
    right: 0;
    background: var(--panel-bg);
    padding: 1rem;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.5);
    border: 1px solid var(--border-color);
    z-index: 20;

    transition: all 0.3s;
    opacity: 0;
    pointer-events: none;

    .feedback-result {
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: var(--panel-bg);
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s;

      &.sent {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  small {
    display: block;
    margin: 0 0 1rem;
    opacity: 0.75;
    b {
      font-weight: 500;
    }
  }

  &.active {
    > button {
      opacity: 1;
      background: var(--menu-active-bg);
      border-color: var(--menu-active-bg);
    }
    .feedback-window {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;
