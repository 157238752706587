import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";
import { Content } from "src/shared/Common";

export const NotFound: React.FC = () => {
  return (
    <Wrapper>
      <h2>Not found</h2>
      <p>We couldn't find what that you were looking for. Sorry!</p>
      <Link to="/">Go back to the dashboard</Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
`;

export default NotFound;
