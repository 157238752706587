import React from "react";
import Button from "./Button";
import styled from "@emotion/styled";

export interface PageProps {
  page: number;
  totalPages: number;
  totalItems: number;
}

interface Props {
  style?: object;
  page: PageProps;
  onChange: (page: number) => void;
}

const Wrapper = styled.div`
  font-size: 13px;
  display: flex;
  justify-content: center;

  button + button {
    margin-left: 8px !important;
  }
`;

const Divider = styled.span`
  display: flex;
  margin: 0 10px;
  align-items: center;
  opacity: 0.3;
`;

export default (props: Props) => {
  const { onChange } = props;
  const { page, totalPages } = props.page;

  return (
    <Wrapper style={props.style}>
      {
        <Button disabled={page <= 1} onClick={() => onChange(page - 1)}>
          Previous
        </Button>
      }

      {page > 4 && <Divider>...</Divider>}

      {[3, 2, 1].map((n: number) => {
        const x = page - n;
        return x > 0 && <Button onClick={() => onChange(x)}>{x}</Button>;
      })}

      <Button
        kind={totalPages <= 1 ? undefined : "primary"}
        disabled={totalPages <= 1}
      >
        <b>{page || 1}</b>
      </Button>

      {[1, 2, 3].map((n: number) => {
        const x = page + n;
        return (
          x <= totalPages && <Button onClick={() => onChange(x)}>{x}</Button>
        );
      })}

      {page < totalPages - 3 && <Divider>...</Divider>}

      {
        <Button
          disabled={page === totalPages || totalPages <= 1}
          onClick={() => onChange(page + 1)}
        >
          Next
        </Button>
      }
    </Wrapper>
  );
};
