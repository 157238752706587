import { useEffect, useRef } from "react";

export const KEYS: { [key: string]: string } = {
  ESC: "Escape",
};

type Handler = (event: KeyboardEvent) => void;

export const useKeydownListener = (handler: Handler, element = window) => {
  const savedHandler = useRef<Handler>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventListener = (event: KeyboardEvent) =>
      savedHandler.current && savedHandler.current(event);
    element.addEventListener("keydown", eventListener);
    return () => {
      element.removeEventListener("keydown", eventListener);
    };
  }, [element]);
};
