import { DateTime } from "luxon";

// diffNow is a minor adjustment to Luxon's relative() code, changing < 10 seconds
// to "just now".  Sometimes our API responds quickly and Luxon says "-1 second"...
// No bueno!   We also want to show the actual time when displaying things relatively.
export const diffNow = (time: string | DateTime) => {
  if (!time) {
    return "-";
  }
  return diff(time, DateTime.local());
};

export const diff = (a: string | DateTime, b: string | DateTime) => {
  try {
    const first: DateTime = stringToDateTime(a);
    const diff = delta(first, b);
    if (diff <= 10) {
      return `just now`;
    }
    const rel = first.toRelative();
    return `${rel}`;
  } catch (e) {
    return "-";
  }
};

export const delta = (a: string | DateTime, b: string | DateTime) => {
  // convert string to datetime
  const first: DateTime = stringToDateTime(a);
  const second: DateTime = stringToDateTime(b);

  const delta = first.diff(second);
  return delta.as("second");
};

export const stringToDateTime = (time: string | DateTime): DateTime => {
  if (typeof time === "string") {
    return DateTime.fromISO(time);
  }
  return time;
};

export const defaultTimeFormat = (str: string, utc?: boolean) => {
  if (str.length > 10 && str.indexOf("T00:00:00Z") > 0) {
      return str.substr(0, 10);
  }
  const dt = DateTime.fromISO(str)
  if (utc) {
    return dt.toUTC().toLocaleString(DateTime.DATETIME_SHORT);
  }
  return dt.toLocaleString(DateTime.DATETIME_SHORT);
};

export const defaultDateFormat = (str: string) => {
  return DateTime.fromISO(str).toLocaleString(DateTime.DATE_SHORT);
};
