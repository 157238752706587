import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useFormState } from "react-use-form-state";
import Box from "src/shared/Box";
import Logo from "src/shared/Icons/Logo";
import styled from "@emotion/styled";
import Button from "src/shared/Button";
import useToast from "src/shared/Toast";
import useSelf from "src/state/self";
// local
import BG from "./BG";

export default () => {
  const [loading, setLoading] = useState(false);
  const [formState, { password }] = useFormState();
  const history = useHistory();
  const { push } = useToast();
  const { refetch } = useSelf();

  const disabled =
    !formState.values.password ||
    formState.values.password !== formState.values.repeat;

  const handleSubmit = async (e: React.SyntheticEvent) => {
    setLoading(true);
    e.preventDefault();

    const result = await fetch(
      `${process.env.REACT_APP_API_HOST}/v1/reset-password/reset`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          password: formState.values.password,
          token: new URLSearchParams(window.location.search).get("token") || "",
        }),
      }
    );

    setLoading(false);
    if (result.status !== 200) {
      push({
        type: "error",
        message:
          "There was an error resetting your password.  Did your link expire?",
      });
      return;
    }

    push({ type: "success", message: "Your password has been reset" });

    refetch();
    history.push("/");
  };

  return (
    <BG>
      <Logo width={100} fill="#fff" style={{ margin: "20px 0" }} />
      <Box>
        <Heading>Enter your new password</Heading>

        <Form onSubmit={handleSubmit}>
          <input {...password("password")} placeholder="Your new password" />
          <input {...password("repeat")} placeholder="Repeat your password" />
          <Button
            type="submit"
            size="large"
            disabled={disabled}
            loading={loading}
            kind={disabled ? undefined : "primary"}
          >
            Reset your password
          </Button>
        </Form>
      </Box>
      <Reg to="/login">Back to login</Reg>
    </BG>
  );
};

const Form = styled.form`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  button {
    width: 100%;
    text-align: center;
    display: block;
    margin-top: 30px;
  }
`;

const Heading = styled.p`
  margin: 0;
  padding: 0 0 30px;
  text-align: center;
`;

const Reg = styled(Link)`
  font-size: 14px;
  margin: 16px 0;
  color: #fff;
  background: rgba(0, 0, 0, 0.1);
  padding: 6px 12px;
`;
