import { useHistory } from "react-router-dom";
import {
  createBrowserHistory,
  Location,
  Action,
  LocationListener,
  UnregisterCallback,
  LocationState,
  LocationDescriptor,
  LocationDescriptorObject,
  Path,
  Href,
} from "history";

const TEST_STORAGE_KEY = "isTest";

export const trimTestPrefix = (path: string) => {
  return path.replace(/^\/test/, "");
};

// Paths where test mode is not relevant - any `/test` prefix will be removed the the banner will be hidden
const nonTestBasePaths = ["/settings", "/integrations"];

export const isNonTestPath = (url: string): boolean => {
  return !!nonTestBasePaths.find((t) => url.indexOf(t) === 0);
};

// This is used such that we can use the history from the logout
// exchange in urql, redirecting to login any time theres a 401.
export const ogHistory = createBrowserHistory();

// isTest stores whether we're in test mode.  This is a mutable variable which is set
// at the top-level react context.  It allows us to check for test mode in our history proxy.
//
// Will use local storage preference to decide on value if not explicitly set.
let isTest =
  window.location.pathname.indexOf("/test/") === 0 ||
  (window.localStorage.getItem(TEST_STORAGE_KEY) ?? "true") === "true";

// history wraps ogHistory, silently adding "/test" prefixes ot the browser's
// URL but hiding them from react-router, allowing us to render a single Route
// tree with optional test prefixes.
const history = {
  // Add all of our history props, and overwrite selectively.
  ...ogHistory,

  // setTest updates the test closure variable.
  setTest: (to: boolean) => {
    isTest = to;
    window.localStorage.setItem(TEST_STORAGE_KEY, to.toString());
  },

  get isTest(): boolean {
    return isTest;
  },

  // get returns the current location.  If we're in test mode, we remove the "test"
  // prefix so that react-router can render all routes without our prefix.
  get location() {
    const location = { ...ogHistory.location };
    if (isTest) {
      // remove the prefix, ensuring react-router gets well-defined routes.
      location.pathname = trimTestPrefix(location.pathname);
    }
    return location;
  },

  // listen is called in React Router's top level Router component.  It listens to
  // route changes and internally calls setState with the new location. We need to
  // override this to silently remove "/test" prefixes for our routes to render.
  listen(listener: LocationListener<LocationState>): UnregisterCallback {
    // A wrapped callback trimming test prefixes.
    const callback = (location: Location, action: Action) => {
      if (isTest) {
        location.pathname = trimTestPrefix(location.pathname);
      }
      listener(location, action);
    };

    // Return unlisten, calling our wrapper which invokes router's listener.
    return ogHistory.listen(callback);
  },

  // createHref is used in React Router's Link component to generate URLs.  This
  // wrapper silently adds "/test" to routes if we're in test mode.
  createHref: (location: LocationDescriptorObject<LocationState>): Href => {
    const href = ogHistory.createHref(location);
    if (isTest && !isNonTestPath(href)) {
      return "/test" + href;
    }
    return href;
  },

  // Push is called by react router when clicking on a link.
  push: function (
    path: Path | LocationDescriptor<LocationState>,
    state?: LocationState
  ) {
    const [transformedPath, transformedState] = normalizePathState(path, state);
    if (typeof transformedState === "string") {
      ogHistory.push(transformedPath as string, transformedState);
      return;
    }
    ogHistory.push(transformedPath);
  },

  replace: function (
    path: Path | LocationDescriptor<LocationState>,
    state?: LocationState
  ) {
    const [transformedPath, transformedState] = normalizePathState(path, state);
    if (typeof transformedState === "string") {
      ogHistory.replace(transformedPath as string, transformedState);
      return;
    }
    ogHistory.replace(transformedPath);
  },
};

export default history;

const normalizePathState = function (
  path: Path | LocationDescriptor<LocationState>,
  state?: LocationState
): [Path | LocationDescriptor<LocationState>, LocationState | undefined] {
  if (typeof path === "string") {
    if (isNonTestPath(path)) {
      return [path, state];
    }

    if (isTest) {
      // Always ensure we push "/test" prefixes in test mode so that the browser has
      // a "/test" URL.  This isn't visible to any part of our system due to the above
      // wrappers.
      path = path.indexOf("/test") === 0 ? path : "/test" + path;
    } else {
      // Remove test prefixes in non-test mode.
      path = trimTestPrefix(path);
    }

    return [path, state];
  }

  if (!path.pathname) {
    return [path, state];
  }

  if (isTest && !isNonTestPath(path.pathname)) {
    // Again, add test prefixes in test mode so we have proper browser URLs.
    path.pathname =
      path.pathname.indexOf("/test") === 0
        ? path.pathname
        : "/test" + path.pathname;
  } else {
    path.pathname = trimTestPrefix(path.pathname);
  }
  return [path, state];
};

export const useSearchParams = () => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  return params;
};

export const usePageFromQuery = (name: string = "page") => {
  const params = useSearchParams();
  return parseInt(params.get(name) || "1", 10) || 1;
};

export const useCursorFromQuery = (): string | undefined => {
  const params = useSearchParams();
  return params.get("cursor") || undefined;
};
