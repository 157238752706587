import React from "react";
import { css } from "@emotion/core";
import { useLocation } from "react-router-dom";
import Tooltip from "rc-tooltip";
import { useWorkspaces } from "src/state/workspaces";
import styled from "@emotion/styled";
import Nav from "./Nav";
import { GlobalSearch } from "./GlobalSearch/GlobalSearch";
import { useSelf } from "src/state/self";
import Feedback from "./Feedback";
import Changelog from "./Changelog";
import Info from "src/shared/Icons/Info";
import { isNonTestPath } from "src/utils/history";

type Props = {
  children: React.ReactNode;
};

export default ({ children }: Props) => {
  const { test } = useWorkspaces();
  const location = useLocation();
  useSelf();

  const workflowEditor =
    location.pathname.indexOf("/workflows/new") !== -1 ||
    location.pathname.search(/workflows\/.*\/versions\/\d$/) !== -1;

  return (
    <>
      <Wrapper css={test && testWrapperCSS}>
        <Nav />
        <Content>
          {
            <TestBanner
              css={test && !isNonTestPath(location.pathname) && visibleCSS}
            >
              You are in test mode{" "}
              <Tooltip
                placement="right"
                overlay={
                  <>
                    Test mode is a staging-like sandbox. Switch to production
                    using the toggle in the left panel.
                  </>
                }
              >
                <TooltipSVGWrapper>
                  <Info size="1.5em" />
                </TooltipSVGWrapper>
              </Tooltip>
            </TestBanner>
          }

          {!workflowEditor && (
            <Top>
              <GlobalSearch />
              <Changelog />
              <Feedback />
            </Top>
          )}

          {children}
        </Content>
      </Wrapper>
    </>
  );
};

const visibleCSS = css`
  height: 35px;
  line-height: 35px;
  color: var(--blue-left);
`;

const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  position: relative;
`;

const testWrapperCSS = css`
  min-height: calc(100vh - 27px);
`;

const Content = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const TestBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  z-index: 1;
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  height: 0;
  line-height: 1px;
  background: var(--color-orange);
  transition: all 0.3s;
  overflow: hidden;
  color: var(--color-orange);
`;

const TooltipSVGWrapper = styled.span`
  display: flex;
`;

const Top = styled.div`
  height: 60px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px 0 20px;

  & + div {
    align-self: stretch;
  }
`;
