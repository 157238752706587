import React from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { css, SerializedStyles } from "@emotion/core";
import { Dropdown } from "./Dropdown/Dropdown";
import { ReactDropdownProps } from "react-dropdown";

// Button props
type Props = React.HTMLAttributes<any> & {
  kind?: KindStrings;
  size?: SizeStrings;
  loading?: boolean;
  type?: "button" | "reset" | "submit" | undefined;
  onClick?: (() => void) | ((e: React.SyntheticEvent) => any);
  disabled?: boolean;
  link?: string;
  target?: string;
  style?: object;
  className?: string;
  extraCss?: SerializedStyles;
  children: React.ReactNode;

  options?: ReactDropdownProps;

  // wide widens the horizontal padding
  wide?: boolean;
};

type ButtonGroupProps = {
  children: React.ReactNode;
  center?: boolean;
  right?: boolean;
  small?: boolean;
  stretch?: boolean;
  style?: any;
  packed?: boolean;
};

export const ButtonGroup = ({
  children,
  right,
  center,
  small,
  stretch,
  style,
  packed,
}: ButtonGroupProps) => (
  <Group
    style={style}
    className="buttongroup"
    css={[
      packed &&
        css`
          > button {
            border-radius: 0;
            &:hover {
              z-index: 1; /* Fix border overlap */
            }
          }
          > button:first-of-type {
            border-top-left-radius: var(--border-radius);
            border-bottom-left-radius: var(--border-radius);
          }
          > button:last-of-type {
            border-top-right-radius: var(--border-radius);
            border-bottom-right-radius: var(--border-radius);
          }
          > button + button {
            margin-left: calc(-1 * var(--button-border-width));
          }
        `,
      right &&
        css`
          justify-content: flex-end;
        `,
      center &&
        css`
          justify-content: center;
        `,
      stretch &&
        css`
          justify-content: stretch;
          button {
            justify-content: center;
          }
          button:only-child {
            flex: 1;
            width: 100%;
          }
        `,
      small &&
        css`
          font-size: 12.8px;
        `,
    ]}
  >
    {children}
  </Group>
);

export default React.forwardRef<HTMLButtonElement, Props>(
  (props: Props, ref) => {
    const history = useHistory();
    const { kind, size, link, children, loading, extraCss, ...rest } = props;
    let { onClick } = props;

    let C: any = Button;
    // lets us smartly apply "href" to link components
    let cProps = {};

    if (link) {
      C = Link;
      cProps = { href: link };
      onClick = (e: React.SyntheticEvent) => {
        if (props.target !== undefined) {
          // use a normal handler to open a tab if there's target="_blank" etc.
          return;
        }
        if (link.indexOf("://") !== -1) {
          window.location.href = link;
          return;
        }
        e.preventDefault();
        history.push(link);
      };
    }

    return (
      <>
        <C
          {...cProps}
          ref={ref}
          css={[
            kind && kinds[kind],
            size && sizes[size],
            props.wide && wideCSS,
            props.options && optionsCSS,
            loading &&
              css`
                opacity: 0.75;
              `,
            extraCss,
          ]}
          {...rest}
          onClick={onClick}
          className={`button ${props.className || ""}`}
        >
          {loading ? "Loading..." : children}
        </C>
        {props.options && <StyledDropdown {...props.options} />}
      </>
    );
  }
);

export const buttonCSS = css`
  display: flex;
  padding: 11px 14px 10px;
  align-items: center;

  font-size: inherit;
  line-height: calc(1em + 1px);
  font-weight: 500;

  border: var(--button-border-width) solid var(--button-border-default);
  border-radius: var(--border-radius);
  background: transparent;
  color: var(--button-text-color);
  box-shadow: 0;
  text-decoration: none;
  transition: all 0.3s;

  cursor: pointer;
  align-items: center;

  &:hover {
    box-shadow: 0 0 10px 0 var(--button-border-default);
    border-color: var(--button-border-default-hover);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--button-disabled-color);
    color: var(--button-disabled-text-color);
    border-color: transparent;
  }
  &:disabled:hover {
    box-shadow: none;
  }

  & + button {
    margin-left: 10px;
  }

  svg,
  img {
    margin-right: 6px;
    align-self: center;
  }
`;

const Link = styled.a`
  ${buttonCSS};
`;

const Button = styled.button`
  ${buttonCSS}
`;

export const primaryCSS = css`
  border-color: transparent;
  background: var(--color-primary);
  color: var(--color-white);

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 10px 2px var(--color-primary-transparent);
  }
`;

const defaultCSS = css``;

const wideCSS = css`
  padding-left: 2rem;
  padding-right: 2rem;
`;

const dashed = css`
  border-style: dashed;
  background: transparent;
`;

const link = css`
  background: transparent;
  box-shadow: none;
  padding: 0.25rem;
  color: inherit;
  text-decoration: underline;
  font-weight: inherit;

  border: var(--button-border-width) solid transparent;

  &:hover {
    background: transparent;
    color: inherit;
    box-shadow: none;
    transform: none;
  }
`;

const danger = css`
  background: var(--color-error);
  color: var(--color-white);
  border-color: transparent;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 10px 2px var(--color-error-transparent);
  }
`;

const greenCSS = css`
  background: var(--color-green);
  color: var(--color-dark-green);
  border-color: transparent;
`;

const outlineWhite = css`
  background: transparent;
  border-color: #ffffffee;
  color: #fff;

  &:hover {
    border-color: #ffffff;
    background: #ffffff11;
    color: #fff;
  }
`;

const optionsCSS = css`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const StyledDropdown = styled(Dropdown)`
  margin: 0;
  display: flex;
  align-self: stretch;
  border-left: 1px solid var(--border-color);

  .Dropdown-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--color-white);
    padding-right: 2rem;
  }

  .Dropdown-arrow {
    margin-top: 2px;
  }
`;

const large = css`
  padding: 16px 16px 14px;
  font-size: 1.125rem;
`;

const medium = css`
  padding: 11px 24px 11px;
`;

const small = css`
  padding: 5px 12px 4px;
  font-size: 0.8rem;

  & + button {
    margin-left: 8px;
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  > div + div {
    margin-left: 12px;
  }

  span {
    margin: 0 1rem;
  }

  label + & {
    margin-top: 30px;
  }

  button {
    display: flex;
    align-items: center;
  }
`;

enum Sizes {
  large,
  medium,
  small,
}

type SizeStrings = keyof typeof Sizes;

const sizes: { [key in SizeStrings]: SerializedStyles } = {
  large: large,
  medium: medium,
  small: small,
};

enum Kinds {
  default,
  primary,
  link,
  green,
  danger,

  // legacy
  submit,
  dashed,
  outlineWhite,
}

export type KindStrings = keyof typeof Kinds;

const kinds: { [key in KindStrings]: SerializedStyles } = {
  primary: primaryCSS,
  submit: primaryCSS,
  default: defaultCSS,
  green: greenCSS,

  dashed: dashed,
  danger: danger,
  outlineWhite: outlineWhite,
  link: link,
};
