import React, { useContext, useState } from "react";
import {
  REACT_APP_INGEST_API_HOST,
  REACT_APP_INNGEST_SOURCE_KEY_FEEDBACK,
} from "src/utils/env";

type ContextType = {
  visible: boolean;
  content: string;
  setVisible: (to: boolean) => void;
  setContent: (text: string) => void;
  showWith: (text: string) => void;

  sent: boolean;
  // send allows you to send the given text, defaulting to the text within context.
  send: (text?: string) => Promise<any>;
};

const FeedbackContext = React.createContext<ContextType>({
  content: "",
  setContent: (_to: string) => {},
  visible: false,
  setVisible: (_to: boolean) => {},
  showWith: (_text: string) => {},
  sent: false,
  send: async (_text?: string) => {},
});

export const FeedbackProvider: React.FC = ({ children }) => {
  const [visible, updateVisible] = useState(false);
  const [content, setContent] = useState<string>("");
  const [sent, setSent] = React.useState(false);

  const setVisible = (to: boolean) => {
    window.setTimeout(() => setSent(false), 300);
    updateVisible(to);
  };

  const send = async (text?: string) => {
    const feedback = text || content;

    if (globalThis.Inngest && typeof globalThis.Inngest.event === "function") {
      await globalThis.Inngest.event(
        {
          name: "app.feedback.created",
          data: { feedback },
        },
        {
          key: REACT_APP_INNGEST_SOURCE_KEY_FEEDBACK,
          host: REACT_APP_INGEST_API_HOST,
        }
      );
    }

    setContent("");
    setSent(true);
    window.setTimeout(() => {
      visible && setVisible(false);
    }, 3000);
  };

  return (
    <FeedbackContext.Provider
      value={{
        sent,
        send,
        visible,
        setVisible,
        content,
        setContent,
        showWith: (text: string) => {
          setContent(text);
          setVisible(true);
        },
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};

export const useFeedback = (): ContextType => {
  return useContext(FeedbackContext);
};
