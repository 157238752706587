import React from "react";
import ReactDOM from "react-dom";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

import "./index.css";
import Routes from "./Routes";
import * as serviceWorker from "./serviceWorker";

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  });

  ReactDOM.render(
    <LDProvider>
      <Routes />
    </LDProvider>,
    document.getElementById("root")
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
