import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useFormState } from "react-use-form-state";
// shared
import useToast from "src/shared/Toast";
import useSelf from "src/state/self";
import Top, { Actions } from "src/shared/TopHeader";
import Breadcrumbs from "src/shared/Breadcrumbs";
import Button, { ButtonGroup } from "src/shared/Button";
import { PageTitle, Content } from "src/shared/Common";
import Box from "src/shared/Box";
import { diffNow } from "src/utils/time";
import { useUpdateUserAccount } from "./query";
import AdminTabs from "./AdminTabs";

const Admin = () => {
  const { push } = useToast();
  const [saving, setSaving] = useState(false);

  const { data } = useSelf();

  const updateUserAccount = useUpdateUserAccount();

  const [form, { text, email, password }] = useFormState({
    name: data ? data.session?.user.name : "",
    email: data ? data.session?.user.email : "",
    company: data ? data.account.name : "",
    password: null,
  });

  const changedAt = useMemo(() => {
    if (!data?.session?.user.passwordChangedAt) {
      return "Never set";
    }
    if (
      !data ||
      data.session.user.createdAt === data.session.user.passwordChangedAt
    ) {
      return "Never changed";
    }
    const rel = diffNow(data.session.user.passwordChangedAt);
    return `Last changed ${rel}`;
  }, [data, data && data.session?.user]);

  if (!data) {
    return null;
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (saving) {
      return;
    }

    setSaving(true);
    const result = await updateUserAccount({
      user: {
        name: form.values.name,
        // Only update the email if it's different.
        email:
          form.values.email !== data.session?.user.email
            ? form.values.email
            : undefined,
        password: form.values.password ? form.values.password : undefined,
      },
      acct: {
        name: form.values.company,
      },
    });
    setSaving(false);
    if (result.error) {
      push({ type: "error", message: result.error.toString() });
      return;
    }
    push({ type: "success", message: "Account updated" });
  };

  return (
    <>
      <PageTitle>
        <div>
          <span>Settings</span>
          <h1>Account</h1>
        </div>
        <div></div>
      </PageTitle>

      <Content>
        <AdminTabs />

        <Form onSubmit={onSubmit}>
          <div>
            <Box title="Your details">
              <label>
                Name
                <input {...text("name")} placeholder="What's your name?" />
              </label>

              <label>
                Email address
                <input {...email("email")} />
              </label>

              <label>
                Company name
                <input {...text("company")} placeholder="Your company?" />
              </label>

              <ButtonGroup right style={{ marginTop: "20px" }}>
                <Button
                  kind="primary"
                  type="submit"
                  onClick={onSubmit}
                  disabled={saving}
                >
                  Save
                </Button>
              </ButtonGroup>
            </Box>
          </div>

          <Box title="Security">
            <label>
              {data?.session?.user.passwordChangedAt
                ? "Change your password"
                : "Set your password"}
              <small>{changedAt}</small>
              <input {...password("password")} />
            </label>

            <ButtonGroup right style={{ marginTop: "20px" }}>
              <Button
                kind="primary"
                type="submit"
                onClick={onSubmit}
                disabled={saving}
              >
                Save
              </Button>
            </ButtonGroup>
          </Box>
        </Form>
      </Content>
    </>
  );
};

const Form = styled("form")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  align-items: flex-start;
`;

const marginTopCSS = css`
  margin-top: 1rem;
`;

export default Admin;
