import styled from "@emotion/styled";

export { PageTitle, Aside } from "./PageTitle";
export { Loading } from "./Loading";
export { Error } from "./Error";
export { Box } from "./Box";

export const Content = styled.div<{ popup?: boolean }>`
  padding: 0 ${({ popup }) => (popup ? "0" : "40px")} 40px;
  flex: 1;
`;
