// Source coolicons.cool
import React from "react";
import type { IconProps } from "./props";

const ArrowCaretCircleRight = ({
  size = "16",
  fill = "currentColor",
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 8C13.5 11.0376 11.0376 13.5 8 13.5V14.5C11.5899 14.5 14.5 11.5899 14.5 8H13.5ZM8 13.5C4.96243 13.5 2.5 11.0376 2.5 8H1.5C1.5 11.5899 4.41015 14.5 8 14.5V13.5ZM2.5 8C2.5 4.96243 4.96243 2.5 8 2.5V1.5C4.41015 1.5 1.5 4.41015 1.5 8H2.5ZM8 2.5C11.0376 2.5 13.5 4.96243 13.5 8H14.5C14.5 4.41015 11.5899 1.5 8 1.5V2.5Z"
      fill={fill}
    />
    <path
      d="M7.68681 5.64645C7.49154 5.45118 7.17496 5.45118 6.9797 5.64645C6.78444 5.84171 6.78444 6.15829 6.9797 6.35355L7.68681 5.64645ZM9.33325 8L9.6868 8.35355C9.88207 8.15829 9.88207 7.84171 9.6868 7.64645L9.33325 8ZM6.9797 9.64645C6.78444 9.84171 6.78444 10.1583 6.9797 10.3536C7.17496 10.5488 7.49154 10.5488 7.68681 10.3536L6.9797 9.64645ZM6.9797 6.35355L8.9797 8.35355L9.6868 7.64645L7.68681 5.64645L6.9797 6.35355ZM8.9797 7.64645L6.9797 9.64645L7.68681 10.3536L9.6868 8.35355L8.9797 7.64645Z"
      fill={fill}
    />
  </svg>
);

export default ArrowCaretCircleRight;
