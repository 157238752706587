import React, { Suspense, useEffect } from "react";
import { Route, Router, Switch } from "react-router-dom";
import {
  cacheExchange,
  createClient,
  dedupExchange,
  fetchExchange,
  Provider as UrqlProvider,
} from "urql";

import AnalyticsWrapper from "src/shared/AnalyticsWrapper";
import { ConfirmWrapper } from "src/shared/Confirm";
import Content from "src/shared/Content";
import { ToastWrapper } from "src/shared/Toast";
import Uploader from "src/shared/Uploader/Uploader";
import Wrapper from "src/shared/Wrapper";
import history from "src/utils/history";
import logoutExchange from "src/utils/logoutExchange";
// state
import { FeedbackProvider } from "src/state/feedback";
import { WorkspaceProvider } from "src/state/workspaces";
// Scenes
import DeviceAuth from "src/scenes/Auth/DeviceAuth";
import EmailUnsubscribe from "src/scenes/Auth/EmailUnsubscribe";
import Login from "src/scenes/Auth/Login";
import Register from "src/scenes/Auth/Register";
import ResetPassword from "src/scenes/Auth/ResetPassword";
import ResetPasswordNew from "src/scenes/Auth/ResetPasswordNew";
import NotFound from "src/scenes/NotFound/NotFound";
import NewWorkspace from "src/scenes/Workspaces/New";

// admin scenes
import Admin from "src/scenes/Admin/Admin";
import APIWebhookAdmin from "src/scenes/Admin/APIWebhook/APIWebhook";
import BillingAdmin from "src/scenes/Admin/Billing/Billing";
import OrgAdmin from "src/scenes/Admin/Org/Org";
import Slack from "src/scenes/Admin/Slack/Slack";
import UserAdmin from "src/scenes/Admin/Users/Users";

import { Loading } from "src/shared/Common";
import { trimTestPrefix } from "src/utils";
import { isNonTestPath } from "src/utils/history";

import * as fullstory from "@fullstory/browser";
import { VercelIntegration } from "./scenes/Integrations/Vercel";

const Events = React.lazy(() => import("src/scenes/Events"));
const Workflows = React.lazy(() => import("src/scenes/Workflows"));
const Functions = React.lazy(() => import("src/scenes/Functions"));
const Contacts = React.lazy(() => import("src/scenes/Contacts"));
const Integrations = React.lazy(() => import("src/scenes/Integrations"));
const Sources = React.lazy(() => import("src/scenes/Sources"));
const Secrets = React.lazy(() => import("src/scenes/Secrets"));

const urqlClient = createClient({
  exchanges: [dedupExchange, cacheExchange, logoutExchange, fetchExchange],
  url: `${process.env.REACT_APP_API_HOST}/gql`,
  requestPolicy: "cache-and-network",
  fetchOptions: () => {
    return {
      credentials: "include",
    };
  },
});

const useStripe = () => {
  useEffect(() => {
    const el = document.createElement("script");
    el.src = "https://js.stripe.com/v3/";
    document.body.appendChild(el);
    return () => el.remove();
  });
};

const Routes: React.FC = () => {
  useStripe();

  const [isTest, setTest] = React.useState(history.isTest);

  const updateTest = (to: boolean) => {
    if (to === isTest) {
      return;
    }

    const { pathname } = window.location;
    const nextPath = to ? `/test${pathname}` : trimTestPrefix(pathname);

    // Ensure that our history wrapper correctly trims or adds "/test" to URLs
    // silently to react-router.
    history.setTest(to);
    // Update test, forcing our tree to re-render.
    setTest(to);
    // Redirect to the correct version of our URL if a valid test path
    if (!isNonTestPath(pathname)) {
      history.push(nextPath);
    }
  };

  useEffect(() => {
    fullstory.init({ orgId: "o-1CVB8R-na1" });
  }, []);

  return (
    <ToastWrapper>
      <ConfirmWrapper>
        <Uploader>
          <Router history={history}>
            <UrqlProvider value={urqlClient}>
              <Wrapper>
                <AnalyticsWrapper>
                  {/* AnalyticsWrapper is required to be nested within Wrapper */}
                  <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route
                      exact
                      path="/reset-password/new"
                      component={ResetPasswordNew}
                    />
                    <Route
                      exact
                      path="/reset-password/reset"
                      component={ResetPassword}
                    />
                    <Route exact path="/register" component={Register} />
                    <Route
                      path="/email/unsubscribe"
                      component={EmailUnsubscribe}
                    />
                    <Route path="/">
                      {/* Manages active workspace (there can only be live and test right now) and test mode */}
                      {/* TODO: Workspace route */}
                      <WorkspaceProvider isTest={isTest} setTest={updateTest}>
                        <FeedbackProvider>
                          <Switch>
                            <Route
                              exact
                              path="/device"
                              component={DeviceAuth}
                            />
                            <Route>
                              <Content>
                                <Suspense fallback={<Loading />}>
                                  <Switch>
                                    <Route exact path="/" component={Events} />
                                    {/* Workflows route is necessary to support loading Workflow editor for Function/Edit redirect */}
                                    <Route
                                      path="/workflows"
                                      component={Workflows}
                                    />
                                    <Route
                                      path="/functions"
                                      component={Functions}
                                    />
                                    <Route path="/events" component={Events} />
                                    <Route
                                      path="/sources"
                                      component={Sources}
                                    />
                                    <Route
                                      path="/secrets"
                                      component={Secrets}
                                    />
                                    <Route
                                      exact
                                      path="/integrations"
                                      component={Integrations}
                                    />
                                    <Route
                                      exact
                                      path="/integrations/vercel"
                                      component={VercelIntegration}
                                    />
                                    <Route
                                      path="/contacts"
                                      component={Contacts}
                                    />

                                    <Route
                                      path="/workspaces/new"
                                      component={NewWorkspace}
                                    />

                                    <Route
                                      exact
                                      path="/settings"
                                      component={Admin}
                                    />
                                    <Route
                                      exact
                                      path="/settings/users"
                                      component={UserAdmin}
                                    />
                                    <Route
                                      exact
                                      path="/settings/org"
                                      component={OrgAdmin}
                                    />
                                    <Route
                                      exact
                                      path="/settings/billing"
                                      component={BillingAdmin}
                                    />
                                    <Route
                                      exact
                                      path="/settings/api"
                                      component={APIWebhookAdmin}
                                    />
                                    <Route
                                      exact
                                      path="/settings/slack"
                                      component={Slack}
                                    />
                                    <Route
                                      exact
                                      path="/settings/slack/success"
                                      component={Slack}
                                    />

                                    <Route component={NotFound} />
                                  </Switch>
                                </Suspense>
                              </Content>
                            </Route>
                          </Switch>
                        </FeedbackProvider>
                      </WorkspaceProvider>
                    </Route>
                  </Switch>
                </AnalyticsWrapper>
              </Wrapper>
            </UrqlProvider>
          </Router>
        </Uploader>
      </ConfirmWrapper>
    </ToastWrapper>
  );
};

export default Routes;
