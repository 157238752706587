import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import { useKeydownListener, KEYS } from "src/utils/keyboard";

type Props = {
  onClose: () => void;
  /** Parent component can pass true to force modal to close */
  close?: boolean;
  footer?: React.ReactNode;
};

const Modal: React.FC<Props> = ({ close, onClose, children, footer }) => {
  const [faded, setFaded] = useState(true);
  useEffect(() => setFaded(false), []);

  // Show the faded class then hide.
  const onFadeClose = useCallback(() => {
    setFaded(true);
    window.setTimeout(onClose, 200);
  }, [setFaded, onClose]);

  useEffect(() => {
    if (close) {
      onFadeClose();
    }
  }, [close, onFadeClose]);

  useKeydownListener(({ key }) => {
    if (key === KEYS.ESC) {
      onFadeClose();
    }
  });

  const preventClose = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <BG onClick={onFadeClose} css={[faded && fadedCss]}>
      <ChildrenWrapper onClick={preventClose}>{children}</ChildrenWrapper>
      {footer || null}
    </BG>
  );
};

const fadedCss = css`
  opacity: 0;
  transform: translateY(-10px);
  > div {
    transform: scale(0.95);
  }
`;

const BG = styled.div`
  z-index: 999;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, var(--modal-bg-opacity));
  opacity: 1;
  transition: all 0.2s;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div:first-of-type {
    transition: all 0.2s;
    box-shadow: 0 20px 80px rgba(0, 0, 0, 0.2);
  }

  > .buttongroup {
    margin-top: 20px;
    flex: 0;
  }
`;

const ChildrenWrapper = styled.div`
  background: var(--bg);
`;

export default Modal;
