type Props = {
  size?: string | number;
  color?: string;
  stroke?: string | number;
};

export default ({ size, color, stroke }: Props) => (
  <svg
    width={size || 64}
    height={size || 64}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth={stroke || "1"}
    style={{ fill: "none !important" }}
  >
    <path
      d="M10 17C10.5523 17 11 16.5523 11 16C11 15.4477 10.5523 15 10 15V17ZM5 16V15C4.44772 15 4 15.4477 4 16H5ZM4 21C4 21.5523 4.44772 22 5 22C5.55228 22 6 21.5523 6 21H4ZM10 15H5V17H10V15ZM4 16V21H6V16H4Z"
      fill={color || "#fff"}
    />
    <path
      d="M19.4176 14.9968C18.8569 16.3846 17.9182 17.5872 16.7081 18.468C15.498 19.3488 14.065 19.8725 12.5721 19.9795C11.0792 20.0865 9.58624 19.7726 8.26287 19.0734C6.9395 18.3742 5.83882 17.3177 5.08594 16.0241"
      stroke={color || "#fff"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 7C13.4477 7 13 7.44772 13 8C13 8.55228 13.4477 9 14 9V7ZM19 8V9C19.5523 9 20 8.55228 20 8H19ZM20 3C20 2.44772 19.5523 2 19 2C18.4477 2 18 2.44772 18 3H20ZM14 9H19V7H14V9ZM20 8V3H18V8H20Z"
      fill={color || "#fff"}
    />
    <path
      d="M4.58252 9.00315C5.1432 7.61541 6.08195 6.41278 7.29206 5.53197C8.50217 4.65116 9.93511 4.12749 11.428 4.02048C12.9209 3.91346 14.4139 4.2274 15.7373 4.92661C17.0606 5.62582 18.1613 6.68226 18.9142 7.97584"
      stroke={color || "#fff"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
