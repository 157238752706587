// Source coolicons.cool
import type { IconProps } from "./props";

const ArrowUpRightSmall = ({
  size = "16",
  fill = "currentColor",
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3333 4C11.3333 4.23376 11.2932 4.45815 11.2195 4.66667L13.3333 4.66667C13.7015 4.66667 14 4.96514 14 5.33333V7.44714C13.7915 7.37344 13.5671 7.33333 13.3333 7.33333C12.2288 7.33333 11.3333 8.22876 11.3333 9.33333C11.3333 10.4379 12.2288 11.3333 13.3333 11.3333C13.5671 11.3333 13.7915 11.2932 14 11.2195V13.3333C14 13.7015 13.7015 14 13.3333 14H5.33333C4.96514 14 4.66667 13.7015 4.66667 13.3333V11.2195C4.45815 11.2932 4.23376 11.3333 4 11.3333C2.89543 11.3333 2 10.4379 2 9.33333C2 8.22876 2.89543 7.33333 4 7.33333C4.23376 7.33333 4.45815 7.37344 4.66667 7.44714V5.33333C4.66667 4.96514 4.96514 4.66667 5.33333 4.66667L7.44714 4.66667C7.37344 4.45815 7.33333 4.23376 7.33333 4C7.33333 2.89543 8.22876 2 9.33333 2C10.4379 2 11.3333 2.89543 11.3333 4Z"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowUpRightSmall;
