import styled from "@emotion/styled";
import HeadwayWidget from "@headwayapp/react-widget";

export default () => {
  return (
    <ChangelogWrapper>
      <HeadwayWidget
        account="7O4jjJ"
        id="changelog-widget"
        badgePosition="center-left"
        trigger
      >
        <div>Updates</div>
      </HeadwayWidget>
    </ChangelogWrapper>
  );
};

const ChangelogWrapper = styled.div`
  display: flex;
  align-self: stretch;
  align-items: stretch;
  cursor: pointer;
  padding-left: 1rem;

  > .HW_widget_component_changelog-widget {
    transition: all 0.3s;
    display: flex !important;
    align-items: center;
    background: transparent;
    border: 0;
    color: var(--color);
    padding: 1px 1rem 0;
    border-left: 1px solid var(--bg);
    border-right: 1px solid var(--bg);
    opacity: 0.75;

    &:hover {
      opacity: 1;
      background: var(--menu-active-bg);
    }

    > div {
      padding-left: 1rem;
    }

    > span {
      left: 0;
    }
  }
`;
