type Props = {
  size?: string | number;
  color?: string;
  checkColor?: string;
  fill?: string;
};

export default ({ size, color, checkColor, fill }: Props) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width={size || "64"}
    height={size || "64"}
    viewBox="0 0 16 17"
    color={color || "currentColor"}
  >
    <circle
      cx="8"
      cy="8.5"
      r="8"
      fill={fill || "none"}
      stroke={color || fill || "currentColor"}
      strokeWidth={1}
    />
    <path
      d="M12 6.13809L6.56 11.5781L4 9.01812L4.63809 8.38002L6.56 10.2974L11.3619 5.5L12 6.13809Z"
      fill={checkColor || color || "currentColor"}
    />
  </svg>
);
