import React, { useState } from "react";
import styled from "@emotion/styled";
import { PageTitle, Content } from "src/shared/Common";
import Button, { ButtonGroup } from "src/shared/Button";
import Box from "src/shared/Box";
import { DateTime } from "luxon";
import Loading from "src/shared/Loading";
import useToast from "src/shared/Toast";
import Error from "src/shared/Error";
import Modal from "src/shared/Modal";
import useSelf from "src/state/self";
// local
import AdminTabs from "../AdminTabs";
import CardForm from "./CardForm";
import { useBillingData, useUpdatePM } from "./query";
// import Usage from "./Usage";
import Plans from "./Plans";

type Modal = "card" | null;

const Billing: React.FC = () => {
  const { push } = useToast();
  const [saving, setSaving] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<
    "pending" | "success" | "unsubmitted"
  >("unsubmitted");
  const { data } = useSelf();
  const [modal, setModal] = useState<Modal>(null);
  const start = data ? data.account.createdAt : DateTime.utc().toISO();
  const [fetching, error, billingData, refetch] = useBillingData(start);

  const updatePMRequest = useUpdatePM();

  if (fetching) {
    return <Loading />;
  }

  if (error || !billingData) {
    return <Error />;
  }

  const updatePayment = async (token: { id: string; type: string }) => {
    if (saving) {
      return;
    }
    setSaving(true);
    setPaymentStatus("pending");
    const result = await updatePMRequest({ token: token.id });
    if (result.error) {
      setSaving(false);
      setPaymentStatus("unsubmitted");
      push({ type: "error", message: result.error.message });
      return;
    }
    refetch(); // refetch the billing methods to display in UI
    setPaymentStatus("success");
    push({ type: "success", message: "Card added" });
  };

  return (
    <>
      <PageTitle>
        <div>
          <span>Settings</span>
          <h1>Billing</h1>
        </div>
      </PageTitle>

      <Content>
        <AdminTabs />

        <div>
          <Grid>
            <Plans data={billingData} />
            <div>
              <Box title="Payment details">
                <label>
                  Billing email
                  <small>We'll send receipts here</small>
                  <input
                    placeholder="Billing email"
                    value={billingData.account.billingEmail}
                  />
                </label>

                <p style={{ margin: "30px 0 20px" }}>Payment methods</p>
                {(billingData.account.paymentMethods || []).length === 0 && (
                  <>
                    <p style={{ textAlign: "center", marginBottom: 10 }}>
                      You have no payment methods added.
                    </p>
                    <ButtonGroup center>
                      <Button kind="primary" onClick={() => setModal("card")}>
                        Add a new card
                      </Button>
                    </ButtonGroup>
                  </>
                )}

                {(billingData.account.paymentMethods || [])
                  .sort((a) => (a.default ? -1 : 1))
                  .map((pm) => {
                    return (
                      <p>
                        {pm.last4} - {pm.brand}
                      </p>
                    );
                  })}
              </Box>
            </div>
          </Grid>
        </div>
      </Content>
      {modal !== null && (
        <Modal onClose={() => setModal(null)}>
          {modal === "card" && (
            <CardBox>
              <CardForm
                onTokenize={updatePayment}
                paymentStatus={paymentStatus}
                onCloseModal={() => setModal(null)}
              />
            </CardBox>
          )}
        </Modal>
      )}
    </>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
`;

const CardBox = styled(Box)`
  min-width: 400px;
  min-height: 200px;
`;

export default Billing;
