import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import Loading from "src/shared/Loading";
// re-export as each import needs this.
export { css } from "@emotion/core";

// focusCSS can be used to highlight a row without it being a link
export const focusCSS = css`
  background: #f3f7f3;
`;

// Usage:
//
//       <Table<Event>
//         columns={[{name: "Event name", width: , "Daily"]}
//         columnCSS={css`grid-template-columns: 2fr 130px 2fr 2fr repeat(3, 110px);`}
//         loading={fetching}
//         data={events}
//         row={Row || ({ item, className }) => <div className={className}>{...}</div>}
//         keyFn={(e) => e.name}
//       />

export type Props<T> = {
  columns: Array<ColumnType | string>;
  data: Array<T>;
  keyFn?: (item: T) => string; // used for the key prop when rendering items
  row: React.FunctionComponent<{ item: T }>; // component to render for each row.
  blank?: React.FC | string;
  style?: Object;

  loading?: boolean;
  error?: string;
  className?: any;
};

type ColumnType = {
  name: string;
  width?: string;
  style?: Object;
};

const Table = <T extends object | string | number>(props: Props<T>) => {
  const columns = props.columns.map((c) =>
    typeof c === "string" ? { name: c } : c
  );

  const widths = props.columns.map((c) =>
    typeof c !== "string" && c.width ? c.width : "1fr"
  );
  const columnCSS = css`
    grid-template-columns: ${widths.join(" ")};
  `;

  const R = props.row;
  const mergedRowCSS = css`
    > * {
      ${rowCSS};
      ${columnCSS};
    }
  `;

  return (
    <Wrapper className={`${props.className || ""} table`} style={props.style}>
      <Header css={[columnCSS]}>
        {columns.map((column, n) => (
          <div key={n} style={column.style}>
            {column.name}
          </div>
        ))}
      </Header>
      {props.data.length === 0 && !props.loading && props.blank && (
        <BlankWrapper>
          {typeof props.blank === "string" ? (
            <div>{props.blank}</div>
          ) : (
            <props.blank />
          )}
        </BlankWrapper>
      )}
      {props.loading && <Loading />}
      {props.error && <BlankWrapper>{props.error}</BlankWrapper>}
      {props.data.map((data: T) => (
        <RowWrapper
          key={props.keyFn ? props.keyFn(data) : JSON.stringify(data)}
          css={[mergedRowCSS]}
          className="row"
        >
          <R item={data} />
        </RowWrapper>
      ))}
    </Wrapper>
  );
};

// Typescript doesn't have "type forwarding", so by default React.memo fails with
// generic components.
//
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087
const genericMemo: <T>(c: T) => T = React.memo;

export default genericMemo(Table);

const baseCSS = css`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  align-items: center;
`;

const Wrapper = styled.div`
  .row:nth-of-type(2n) {
    background: var(--color-gray-950);
  }
  .row:nth-of-type(2n + 1) {
    background: var(--color-gray-900);
  }

  .row:last-of-type {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }
`;

const Header = styled.div`
  ${baseCSS};
  text-transform: uppercase;
  letter-spacing: -0.2px;
  font-weight: 500;
  font-size: 13px;
  min-height: 34px;
  padding: 2px 1rem 0;

  background: var(--color-black);
  border-radius: var(--border-radius) var(--border-radius) 0 0;

  > div {
    color: var(--color-gray-200);
  }
`;

// Used to style links, etc. within the table.
const RowWrapper = styled.div`
  > a,
  > button {
    cursor: pointer;
    color: inherit;
    text-decoration: none;

    &:hover {
      background: #eff4f8;
      background: var(--color-gray-800);
    }
  }

  > * {
    padding: 12px 1rem 11px;
  }

  > button {
    text-align: left;
  }

  input[type="checkbox"] {
    box-shadow: none;
  }
`;

const rowCSS = css`
  ${baseCSS};
  min-height: 40px;

  .tag {
    vertical-align: top;
  }

  > div:not(.overflow),
  > div:not(.grid-overflow) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const BlankWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-gray-900);
`;
