import React from "react";
import styled from "@emotion/styled";

// min-height of 40px keeps breadcrumbs in place when there are buttons
// in a header.
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.5;
  margin-bottom: 3px;

  font-size: 11px;
  margin: 3px 0;
  text-transform: uppercase;

  a {
    color: var(--color-dim);
    text-decoration: none;
  }

  a + a {
    &:before {
      display: inline-block;
      width: 20px;
      text-align: center;
      content: " › ";
      text-decoration: none;
    }
  }

  a:last-of-type {
    font-weight: bold;
  }
`;

type Props = {
  children: React.ReactNode;
};

export default ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};
