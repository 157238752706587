import styled from "@emotion/styled";

const BG = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;

  .box {
    box-shadow: 0 8px 35px rgba(0, 0, 0, 0.25);
  }
`;

export default BG;
