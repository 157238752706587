import React from "react";
import styled from "@emotion/styled";

import Alert from "src/shared/Icons/Alert";

type Props = {
  children: React.ReactNode;
};

const Warning = ({ children }: Props) => <Container>{children}</Container>;

const Container = styled.div`
  margin-bottom: 15px;
  padding: 15px;
  background-color: var(--warning-bg);
  border: 1px solid var(--border-color-warning);
  border-radius: 4px;
`;

export default Warning;
