import React, { useReducer, useEffect, useContext } from "react";
import upload, { isError } from "./xhr";
import { FileData } from "./FileData";
import { Headers, PromiseMap } from "./util";
import { reducer } from "./reducer";

const defaultMaxConcurrentUploads = 3;

type Props = {
  children: React.ReactNode;
  // The maximum number of concurrent uploads
  maxUploads?: number;
  // Any HTTP headers that should be added to the request
  headers?: () => Headers;
};

export interface UploadContextData {
  queue: ReadonlyArray<FileData>;
  current: ReadonlyArray<FileData>;
  complete: ReadonlyArray<FileData>;
  error: ReadonlyArray<FileData>;

  // Push pushes one or more FileData entries to the queue to be uploaded
  push(items: ReadonlyArray<FileData>): Promise<any>;
  // Replace replaces the entire queue with a new queue.  This is primarily
  // used internally once items have finished.  We can't pop from the queue
  // as items may finish out of order.
  replace(items: ReadonlyArray<FileData>): void;
  // setProgress updates a FileData's  upload progress.
  setProgress(file: FileData, percent: number): void;
  // mark a file as uploaded
  setComplete(file: FileData): void;
  // mark a file as errored
  setError(file: FileData): void;
}

export const UploadContext = React.createContext<UploadContextData>({
  queue: [],
  current: [],
  complete: [],
  error: [],
  push: async (items: Array<FileData>) => {
    return null;
  },
  replace: (to: ReadonlyArray<FileData>) => to,
  setProgress: (file, percent) => {},
  setComplete: (file) => {},
  setError: (file) => {},
});

type uploadArgs = {
  // all context data for dispatching actions
  u: UploadContextData;
  // number of files to upload from the queue
  num: number;
  // promiseMap
  promises: PromiseMap;
  // function to get headers for uploading files
  headers?: () => Headers;
};

// uploadQueue is a single function which takes contextData, the number of files to upload,
// and a URL to hit to upload.
const uploadQueue = async ({ u, num, headers, promises }: uploadArgs) => {
  const { queue } = u;

  if (queue.length === 0) {
    return;
  }

  const next = queue.slice(0, num);
  const h = headers ? headers() : {};

  debugger;

  next.forEach(async (file) => {
    const { res, rej } = promises.get(file.id) || {
      res: () => {},
      rej: () => {},
    };

    try {
      // Attempt to upload the file
      const result = await upload(file, h, u);
      if (!isError(result)) {
        // TODO: If this succeeded, add this to the succeeded list.
        res(result);
        return;
      }
      // TODO: If this item failed to upload, add it to the failed list.
      u.setError(file);
      rej(result);
    } catch (e) {
      u.setError(file);
      rej(e);
    }
  });

  // Replace the queue with all other items remaining
  u.replace(queue.slice(num));
};

const Uploader: React.FC<Props> = ({
  children,
  headers,
  maxUploads = defaultMaxConcurrentUploads,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    filePromises: new Map(),
    queue: [],
    current: [],
    complete: [],
    error: [],
  });

  const contextData: UploadContextData = {
    queue: state.queue,
    current: state.current,
    complete: state.complete,
    error: state.error,
    push: (items: Array<FileData>): Promise<any> => {
      // Push each item onto our queue with a new promise.
      const promises = items.map((file) => {
        return new Promise((res, rej) => {
          dispatch({ type: "add", file, res, rej });
        });
      });
      return Promise.all(promises);
    },
    replace: (to: ReadonlyArray<FileData>) => dispatch({ type: "replace", to }),
    setComplete: (file: FileData) => dispatch({ type: "complete", file }),
    setError: (file: FileData) => dispatch({ type: "error", file }),
    setProgress: (file: FileData, percent: number) => {
      dispatch({ type: "progress", file, percent });
    },
  };

  useEffect(() => {
    // Only allow N uploads at a time.  When an upload finishes, this
    // effect will be triggered again as it's based off of the queue and
    // progress amounts - therefore triggering the next upload.
    if (maxUploads > state.current.length) {
      uploadQueue({
        u: contextData,
        num: maxUploads - state.current.length,
        headers,
        promises: state.filePromises,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.queue.length,
    state.current.length,
    state.complete.length,
    state.error.length,
    uploadQueue,
    maxUploads,
  ]);

  return (
    <UploadContext.Provider value={contextData}>
      {children}
    </UploadContext.Provider>
  );
};

export const usePush = () => {
  const { push } = useContext(UploadContext);
  return push;
};

export default Uploader;
