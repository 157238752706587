import React, { useState } from "react";
import { useHistory, useLocation, Link, NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useFlags } from "launchdarkly-react-client-sdk";
import Tooltip from "rc-tooltip";

import { useWorkspaces } from "src/state/workspaces";
import useToast from "src/shared/Toast";
import Toggle, { ToggleWrapper } from "src/shared/Toggle";
import useSelf from "src/state/self";

import Logo from "src/shared/Icons/Logo";
import Puzzle from "src/shared/Icons/Puzzle";
import SystemBarLeft from "src/shared/Icons/SystemBarLeft";
import ArrowCaretCircleRight from "src/shared/Icons/ArrowCaretCircleRight";
import InterfaceLoading from "src/shared/Icons/InterfaceLoading";
import SystemData from "src/shared/Icons/SystemData";
import InterfaceSettings from "src/shared/Icons/InterfaceSettings";
import Help from "src/shared/Icons/Help";
import Discord from "src/shared/Icons/Discord";
import UserCircle from "src/shared/Icons/UserCircle";
import ChatCircle from "src/shared/Icons/ChatCircle";
import Developers from "./Icons/Developers";
import Contacts from "./Icons/Contacts";
import Info from "src/shared/Icons/Info";

const COLLAPSED_KEY = "nav-collapsed";

const Nav: React.FC = () => {
  const { test, setTest } = useWorkspaces();
  const history = useHistory();
  const { pathname } = useLocation();
  const { data, refetch } = useSelf();
  const { push } = useToast();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    (() => {
      try {
        return !!JSON.parse(window.localStorage.getItem(COLLAPSED_KEY) || "");
      } catch (e) {
        return false;
      }
    })()
  );
  const { navigationUsers } = useFlags();

  const is = (str: string) => pathname.match(str) !== null;

  const logout = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    await fetch(`${process.env.REACT_APP_API_HOST}/v1/logout`, {
      credentials: "include",
      method: "POST",
    });
    refetch();
    history.push("/login");
  };

  const showIntercomMessenger = () => {
    if (!window.Intercom.booted) {
      push({
        type: "default",
        message:
          "Unable to load Intercom chat. Please disable blocking of third party scripts to chat with support.",
      });
      return;
    }
    window.Intercom("boot", {
      app_id: "qx1ym9xn",
      hide_default_launcher: true,
      user_id: data?.session?.user?.id,
      name: data?.session?.user?.name,
      email: data?.session?.user?.email,
      created_at: data?.session?.user?.createdAt
        ? Math.floor(new Date(data?.session?.user?.createdAt).valueOf() / 1000)
        : null,
      company: {
        company_id: data?.account?.id,
        name: data?.account?.name || "",
        plan: data?.account?.plan?.name || "",
      },
    });
    window.Intercom("show");
  };

  return (
    <NavContainer isCollapsed={isCollapsed}>
      <Header isCollapsed={isCollapsed}>
        <Link className="brand" to="/">
          <Logo style={{ height: "30px" }} />
        </Link>
        <CollapseButton
          onClick={() => {
            setIsCollapsed(!isCollapsed);
            window.localStorage.setItem(
              COLLAPSED_KEY,
              JSON.stringify(!isCollapsed)
            );
          }}
          title={`${isCollapsed ? "Collapse" : "Expand"} menu`}
        >
          <SystemBarLeft size="24" />
        </CollapseButton>
      </Header>
      <Section grow={true}>
        <div>
          <NavLink
            to="/events"
            isActive={() => is("^/events*") || is("^/$")}
            title="Events"
            css={navLinkCSS}
          >
            <InterfaceLoading size="16" />
            <span>Events</span>
          </NavLink>
          <NavLink
            to="/functions"
            isActive={() => is("^/functions*")}
            title="Functions"
            css={navLinkCSS}
          >
            <ArrowCaretCircleRight size="16" />
            <span>Functions</span>
          </NavLink>
          <NavLink
            to="/sources"
            isActive={() => is("^/sources*")}
            title="Sources"
            css={navLinkCSS}
          >
            <SystemData size="16" />
            Sources
          </NavLink>
          <NavLink
            to="/secrets"
            isActive={() => is("^/secrets*")}
            title="Secrets"
            css={navLinkCSS}
          >
            <Developers size={16} />
            Secrets
          </NavLink>
          {navigationUsers && (
            <NavLink
              to="/contacts"
              isActive={() => is("^/contacts*")}
              title="Users"
              css={navLinkCSS}
            >
              <Contacts size={16} />
              Users
            </NavLink>
          )}
        </div>
        <TestMode isTestMode={test} isCollapsed={isCollapsed}>
          <ToggleWrapper>
            <span className="label">
              Test mode
              <Tooltip
                placement="topRight"
                overlay={
                  <>
                    Test mode is a staging-like sandbox.
                    <br />
                    Use this to toggle between Production and Test.
                  </>
                }
              >
                <span className="label-tooltip-wrapper">
                  <Info />
                </span>
              </Tooltip>
            </span>
            <Toggle
              kind="warning"
              checked={test}
              onChange={(e) => setTest(e?.target?.checked || false)}
            />
          </ToggleWrapper>
        </TestMode>
      </Section>
      <Section>
        <NavLink
          to="/settings"
          isActive={() => is("^/settings*")}
          title="Settings"
          css={navLinkCSS}
        >
          <InterfaceSettings size="16" />
          Settings
        </NavLink>
        <NavLink
          to="/integrations"
          isActive={() => is("^/integrations*")}
          title="Integrations"
          css={navLinkCSS}
        >
          <Puzzle size="16" />
          Integrations
        </NavLink>
        <a
          href="https://www.inngest.com/discord?ref=app-nav"
          title="Discord"
          css={navLinkCSS}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Discord size="16" />
          Discord
        </a>
        <a
          href="https://www.inngest.com/docs?ref=app-nav"
          title="Documentation"
          css={navLinkCSS}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Help size="16" />
          Documentation
        </a>
        <button
          onClick={() => showIntercomMessenger()}
          title="Chat with Support"
          css={navLinkCSS}
        >
          <ChatCircle size="16" />
          Chat with Support
        </button>
      </Section>
      <Section>
        <a
          href="/api/v1/logout"
          title="Logout"
          onClick={logout}
          css={navLinkCSS}
        >
          <UserCircle size="16" />
          Logout
        </a>
      </Section>
    </NavContainer>
  );
};

type GlobalProps = { isCollapsed: boolean };

const NavContainer = styled.div<GlobalProps>`
  --base-unit: 8px;
  --nav-width-expanded: calc(var(--base-unit) * 28);
  --nav-width-compressed: calc(var(--base-unit) * 8);

  position: sticky;
  top: 0px;
  display: flex;
  height: 100vh;
  flex-direction: column;
  max-width: calc(
    ${({ isCollapsed }) =>
        isCollapsed
          ? "var(--nav-width-compressed)"
          : "var(--nav-width-expanded)"} + 1px
  );
  border-right: 1px solid var(--border-color);
  transition: var(--transition);
`;

const Header = styled.div<GlobalProps>`
  position: relative;
  left: ${({ isCollapsed }) =>
    isCollapsed
      ? "calc(-1 * var(--nav-width-expanded) + var(--nav-width-compressed))"
      : "0"};
  display: flex;
  width: var(--nav-width-expanded);
  padding: 34px 16px 30px 44px;
  justify-content: space-between;
  align-items: center;
  transition: var(--transition);

  .brand {
    color: var(--color);
  }
`;

const CollapseButton = styled.button`
  display: block;
  height: 28px;
  background: transparent;
  border: none;
  color: var(--color-gray-400);
  transition: var(--transition);

  &:hover {
    color: var(--color-gray-200);
  }
`;

const Section = styled.div<{ grow?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: calc(2 * var(--base-unit));
  flex-grow: ${({ grow }) => (grow ? 1 : 0)};
  border-top: 1px solid var(--border-color);
`;

const navLinkCSS = css`
  display: flex;
  align-items: center;
  padding: var(--base-unit);
  line-height: calc(2 * var(--base-unit));
  overflow: hidden;
  border-radius: var(--border-radius);
  text-decoration: none;
  color: var(--nav-link-color);
  white-space: nowrap;
  background: transparent;
  border: none;

  & + & {
    margin-top: var(--base-unit);
  }

  &:hover,
  &.active {
    background: var(--color-gray-600);
    color: var(--nav-link-color-active);

    svg {
      color: var(--color-turquoise);
    }
  }

  // icon
  svg {
    margin-right: var(--base-unit);
    overflow: visible;
  }
`;

const TestMode = styled.div<GlobalProps & { isTestMode: boolean }>`
  position: relative;
  left: ${({ isCollapsed }) =>
    isCollapsed
      ? "-108px"
      : "0"}; // width of "Test mode" text + left padding + left margin
  padding: 0 var(--base-unit) var(--base-unit);
  color: ${({ isTestMode }) =>
    isTestMode ? "var(--nav-link-color-active)" : "var(--nav-link-color)"};
  white-space: nowrap;
  transition: var(--transition);

  .label {
    display: inline-flex;
    align-items: center;
    gap: 0.4rem;
    opacity: ${({ isCollapsed }) => (isCollapsed ? 0 : 1)};
    transition: var(--transition);
  }
  .label-tooltip-wrapper {
    display: flex;
  }
`;

export default Nav;
