import { FileData } from "./FileData";
import { Headers } from "./util";

// ProgressTracker is an interface which represents functionality used to track progress
// of a given upload.
//
// The Uploader provider fulfils this interface to track uploads and share state in
// React context.
interface ProgressTracker {
  setProgress(f: FileData, n: number): void;
  setComplete(f: FileData): void;
}

export type ErrorResponse = {
  error: string;
};

export type FileResponse = {
  id: string;
};

export type Response = FileResponse | ErrorResponse;

export const isError = (r: Response): r is ErrorResponse => {
  return (r as FileResponse).id === undefined;
};

// upload uploads a given file to the specified endpoint.
const upload = (
  f: FileData,
  headers: Headers,
  p?: ProgressTracker
): Promise<Response> => {
  return new Promise<Response>(function (resolve, reject) {
    const form = new FormData();

    const data = f.data || {};

    Object.keys(data).forEach((attr) => {
      form.append(attr, data[attr]);
    });

    form.append("content", f.file, f.file.name);

    const xhr = new XMLHttpRequest();

    if (p) {
      // Ensure that this file is moved to the queue with 0% progress
      p.setProgress(f, 0);

      xhr.upload.onprogress = (e) => {
        // With 100% progress, this just means that we've sent the file
        // to the server, not that the upload has finished.  We need to wait
        // for the server to continue streaming to S3 and to properly insert
        // to the database. Therefore, in order to make people keep the page
        // open, lie and say that 100% uploaded is 99%, and only trigger complete
        // when we have a response
        const percent = Math.round((e.loaded / e.total) * 100);
        p.setProgress(f, percent === 100 ? 99 : percent);
      };
    }

    xhr.open("POST", f.uploadURL, true);
    xhr.withCredentials = true;

    Object.keys(headers).forEach((h) => {
      xhr.setRequestHeader(h, headers[h]);
    });

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        try {
          resolve(JSON.parse(xhr.response));
        } catch (e) {
          reject({ error: "unable to parse response" });
        }
        if (p) {
          p.setComplete(f);
        }
      }
    };
    xhr.send(form);
  });
};

export default upload;
