import React, { useState } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { useFormState } from "react-use-form-state";
import Box from "src/shared/Box";
import Button, { ButtonGroup } from "src/shared/Button";
import useToast from "src/shared/Toast";
import { useCreateAPIKey, VisibleAPIKey } from "./query";

type Props = {
  onComplete: () => void;
};

const NewAPIKey: React.FC<Props> = ({ onComplete }) => {
  const [created, setCreated] = useState<VisibleAPIKey | null>(null);

  const { push } = useToast();
  const [saving, setSaving] = useState(false);
  const [form, { text }] = useFormState();
  const execute = useCreateAPIKey();

  const onSubmit = async () => {
    if (saving) {
      return;
    }

    setSaving(true);
    const result = await execute({ name: form.values.name });
    setSaving(false);

    if (result.error || !result.data) {
      push({
        type: "error",
        message: result.error ? result.error.toString() : "Please try again",
      });
      return;
    }

    push({
      type: "success",
      message: `Created API key`,
    });

    setCreated(result.data.createAPIKey);
  };

  if (created) {
    return (
      <Box title="Your new API key">
        <SuccessWrapper>
          <p>
            Here's your <b>private</b> API key.
            <br />
            <b>You'll only see this once - copy it and keep it safe!</b>
          </p>

          <input type="text" value={created.private} />

          <ButtonGroup center style={{ marginTop: "20px" }}>
            <Button kind="link" onClick={onComplete}>
              Done
            </Button>
          </ButtonGroup>
        </SuccessWrapper>
      </Box>
    );
  }

  return (
    <Box title="Create an API key">
      <Form onSubmit={onSubmit}>
        <p>
          You can use this when making requests to our
          <br />
          REST API to authenticate.
        </p>
        <label>
          Name
          <input {...text("name")} placeholder="Name" />
        </label>

        <ButtonGroup right style={{ marginTop: "20px" }}>
          <Button
            kind="primary"
            type="submit"
            onClick={onSubmit}
            disabled={saving}
          >
            Create
          </Button>
        </ButtonGroup>
      </Form>
    </Box>
  );
};

const coreCss = css`
  min-width: 600px;

  p {
    text-align: center;
    margin: 0 auto 2rem;
    opacity: 0.7;
    max-width: 70%;
    font-size: 14px;
  }
`;

const SuccessWrapper = styled.div`
  ${coreCss}

  input {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    font-family: "Fira Mono", "DejaVu Sans Mono", Menlo, Consolas,
      "Liberation Mono", Monaco, "Lucida Console", monospace;
  }
`;

const Form = styled.form`
  ${coreCss}
`;

export default NewAPIKey;
