import React, { useState, useEffect } from "react";
import queryString from "query-string";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";

import Button from "src/shared/Button";

const statusToText = {
  "": "",
  unsubscribed: "You are now unsubscribed from this mailing list.",
  subscribed: "You are now subscribed to this mailing list.",
};

const EmailUnsubscribe = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { search } = useLocation();
  const { token } = queryString.parse(search);
  const [status, setStatus] = useState<"" | "unsubscribed" | "subscribed">("");

  const unsubscribe = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API_HOST}/v1/email/unsubscribe`,
      {
        method: "POST",
        body: JSON.stringify({
          token,
        }),
      }
    );

    const body = await res?.json();
    setStatus("unsubscribed");
    if (body && body.error) {
      setError("Oops! There was an error. Please try again later.");
    }
  };

  const resubscribe = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API_HOST}/v1/email/subscribe`,
      {
        method: "POST",
        body: JSON.stringify({
          token,
        }),
      }
    );

    const body = await res?.json();
    setStatus("subscribed");
    if (body && body.error) {
      setError("Oops! There was an error. Please try again later.");
    }
  };

  useEffect(() => {
    if (!token) return;

    unsubscribe();
  }, []);

  if (!token) {
    return <NotFound />;
  }

  return (
    <Container>
      <h3>{statusToText[status]}</h3>
      {error}
      {status === "unsubscribed" && (
        <>
          Didn't mean to?{" "}
          <Button onClick={resubscribe}>Resubscribe to emails</Button>
        </>
      )}
    </Container>
  );
};

const NotFound = () => {
  return <div>404</div>;
};

const Container = styled.div`
  background-color: #f4f4f4;
  padding: 40px;

  min-width: 100vw;
  min-height: 100vh;
`;

export default EmailUnsubscribe;
