import React, { useState } from "react";
import styled from "@emotion/styled";
import { PageTitle, Content } from "src/shared/Common";
import useToast from "src/shared/Toast";
import Button, { ButtonGroup } from "src/shared/Button";
import Box from "src/shared/Box";
import AdminTabs from "../AdminTabs";
import { useAccountIdentifier, useUpdateAccountIdentifier } from "./query";

const Org: React.FC = () => {
  return (
    <div>
      <PageTitle>
        <div>
          <span>Settings</span>
          <h1>Organization</h1>
        </div>
      </PageTitle>

      <Content>
        <AdminTabs />

        <Grid>
          <div>
            <IdentifierBox />
          </div>
        </Grid>
      </Content>
    </div>
  );
};

const IdentifierBox = () => {
  const [{ fetching, data }] = useAccountIdentifier();
  const { push } = useToast();
  const [saving, setSaving] = useState(false);
  const savedPrefix =
    data && data.account.identifier ? data.account.identifier.dsnPrefix : false;
  const [dsnPrefix, setDSNPrefix] = useState(savedPrefix || "");
  const saveAccountIdentifer = useUpdateAccountIdentifier();

  React.useEffect(() => {
    if (!fetching && data) {
      const savedPrefix =
        data && data.account.identifier
          ? data.account.identifier.dsnPrefix
          : "";
      setDSNPrefix(savedPrefix);
    }
  }, [fetching, data]);

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (saving) {
      return false;
    }

    setSaving(true);
    const result = await saveAccountIdentifer({
      input: {
        dsnPrefix,
      },
    });
    setSaving(false);

    if (result.error) {
      push({ type: "error", message: result.error.toString() });
      return;
    }
    push({ type: "success", message: "Account identifier updated" });
  };

  return (
    <Box title="Account identifier">
      <form onSubmit={onSubmit}>
        <label>
          DSN Prefix
          <small>
            Set a DSN prefix to use within your actions. Must be lowercase
            letters with dashes.
          </small>
          <small>
            <strong>This cannot be changed when set.</strong>
          </small>
          <input
            value={dsnPrefix}
            disabled={!!savedPrefix}
            onChange={(e) => {
              setDSNPrefix(
                e.target.value.replace(" ", "-").replace(/[^0-9a-z-]+/g, "")
              );
            }}
            placeholder="example-inc"
          />
        </label>

        <ButtonGroup right style={{ marginTop: "20px" }}>
          <Button
            kind="primary"
            type="submit"
            onClick={onSubmit}
            disabled={saving || !!savedPrefix}
          >
            Save
          </Button>
        </ButtonGroup>
      </form>
    </Box>
  );
};

export default Org;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
`;
