import { FileData } from "./FileData";
import { PromiseMap } from "./util";

export type State = {
  filePromises: PromiseMap;
  queue: ReadonlyArray<FileData>;
  current: ReadonlyArray<FileData>;
  complete: ReadonlyArray<FileData>;
  error: ReadonlyArray<FileData>;
};

export type Action =
  | {
      type: "add";
      file: FileData;
      res: (data?: any) => void;
      rej: (data?: any) => void;
    }
  | { type: "replace"; to: ReadonlyArray<FileData> }
  | { type: "progress"; file: FileData; percent: number }
  | { type: "complete"; file: FileData; response?: any }
  | { type: "error"; file: FileData; error?: any };

export const reducer = (s: State, a: Action) => {
  switch (a.type) {
    case "add":
      s.filePromises.set(a.file.id, {
        res: a.res,
        rej: a.rej,
      });
      return {
        ...s,
        queue: s.queue.concat([a.file]),
      };
    case "replace":
      return { ...s, queue: a.to };
    case "complete": {
      return {
        ...s,
        current: s.current.filter((f) => f.id !== a.file.id),
        complete: s.complete.concat([{ ...a.file, uploadPercent: 100 }]),
      };
    }
    case "error": {
      return {
        ...s,
        current: s.current.filter((f) => f.id !== a.file.id),
        error: s.error.concat([a.file]),
      };
    }
    case "progress":
      return {
        ...s,
        current: s.current
          .filter((f) => f.id !== a.file.id)
          .concat([{ ...a.file, uploadPercent: a.percent }]),
      };
  }
};
