import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { PageTitle, Content } from "src/shared/Common";
import useToast from "src/shared/Toast";
import Button from "src/shared/Button";
import { useWorkspaces, useNewWorkspace } from "src/state/workspaces";

const NewWorkspace: React.FC = () => {
  const { setName: setCurrentName, refetch } = useWorkspaces();
  const [name, setName] = useState("");
  const history = useHistory();
  const { push } = useToast();
  const newWorkspace = useNewWorkspace();

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!name) {
      return;
    }

    const result = await newWorkspace({ input: { name } });

    if (result.error) {
      push({
        type: "error",
        message: `Error creating workspace: ${result?.error?.message}`,
      });
      return;
    }

    push({
      type: "success",
      message: `Workspace created`,
    });

    // Change to new workspace
    await refetch();
    setCurrentName(name);
    history.push("/");
  };

  return (
    <div>
      <PageTitle>
        <h1>New workspace</h1>
      </PageTitle>

      <Content>
        <form onSubmit={onSubmit}>
          <label>
            Workspace name
            <input type="text" onChange={(e) => setName(e.target.value)} />
          </label>
          <Button type="submit" kind="primary">
            Create
          </Button>
        </form>
      </Content>
    </div>
  );
};

export default NewWorkspace;
