import { useQuery, useMutation } from "urql";

export type AccountIdentifier = {
  id: string;
  accountID: string;
  dsnPrefix: string;
  domain?: string | null;
  verifiedAt?: string;
};

export const useAccountIdentifier = () => {
  return useQuery<{ account: { identifier: AccountIdentifier | null } }>({
    query: `
      query AccountUsers {
        account {
          identifier {
            id accountID dsnPrefix domain verifiedAt
          }
        }
      }
      `,
  });
};

export const useUpdateAccountIdentifier = () => {
  const [, execute] = useMutation(`
    mutation UpdateAccountIdentifier($input: AccountIdentifierInput!) {
      updateAccountIdentifier(input: $input) {
        id accountID dsnPrefix domain verifiedAt
      }
    }
  `);
  return execute;
};
