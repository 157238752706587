import styled from "@emotion/styled";

type PageTitleProps = {
  children: React.ReactNode;
  zeroSpacing?: boolean;
};

export const PageTitle = styled.div<PageTitleProps>`
  padding: 0
    ${(props) => (props.zeroSpacing ? "0" : "var(--page-horizontal-spacing)")};
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin: ${(props) =>
    props.zeroSpacing ? "0" : "var(--page-vertical-spacing) 0"};
  line-height: 1;
  position: relative;
  z-index: 1;

  /* keeps height when there are no buttons */
  min-height: 41px;

  > div:first-of-type {
    > span:first-of-type:not(.tag) {
      display: block;
      margin-bottom: 3px;

      text-transform: uppercase;
      opacity: 0.5;
      font-size: 11px;

      svg {
        position: relative;
        bottom: -3px;
        margin-right: 3px;
      }
    }
  }

  .tag + h1 {
    margin-top: 4px;
  }

  h1 {
    display: flex;
    align-items: center;
    min-height: 41px; // height of buttons for consistency across scenes
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;

    small {
      font-size: 14px;
      font-weight: normal;
      margin: 0 0 0 10px;
      opacity: 0.5;
    }

    .tag {
      display: block;
      margin-left: 8px;
    }

    // Icon
    svg {
      margin-left: 8px;
      color: var(--color-gray-200);
    }
  }

  h1 + p {
    margin-top: 0.5rem;
    opacity: 0.6;
  }
`;

export const Aside = styled.div`
  display: flex;
  align-items: center;

  > *,
  > button {
    margin-left: 16px;
  }
`;

export default PageTitle;
