// Source coolicons.cool
import React from "react";
import type { IconProps } from "./props";

const InterfaceLoading = ({
  size = "16",
  fill = "currentColor",
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33325 9.33334C3.33325 8.96515 3.63173 8.66667 3.99992 8.66667C4.36811 8.66667 4.66659 8.96515 4.66659 9.33334C4.66659 9.70153 4.36811 10 3.99992 10C3.63173 10 3.33325 9.70153 3.33325 9.33334Z"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.33325 7.99999C7.33325 7.63181 7.63173 7.33333 7.99992 7.33333C8.36811 7.33333 8.66659 7.63181 8.66659 7.99999C8.66659 8.36818 8.36811 8.66666 7.99992 8.66666C7.63173 8.66666 7.33325 8.36818 7.33325 7.99999Z"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3333 6.66667C11.3333 6.29848 11.6317 6 11.9999 6C12.3681 6 12.6666 6.29848 12.6666 6.66667C12.6666 7.03486 12.3681 7.33333 11.9999 7.33333C11.6317 7.33333 11.3333 7.03486 11.3333 6.66667Z"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default InterfaceLoading;
