import React, { useContext } from "react";
import Loading from "src/shared/Loading";
import { useQuery, useMutation } from "urql";
import { useLDClient } from "launchdarkly-react-client-sdk";

const query = `
  query GetSession {
    session {
      user {
        id
        email
        name
        roles
        passwordChangedAt
        createdAt
      }
      expires
    }
    account {
      id
      name
      billingEmail
      plan {
        id name amount billingPeriod
        features
      }
      status
      createdAt
      updatedAt

      onboarding: setting(name: "onboarding_prompt") { value }
      useCases: setting(name: "use_cases") { value }
      datadogKey: setting(name: "datadog_log_key") { value }

      identifier { dsnPrefix domain verifiedAt }
      usage {
        events { total asOf data { slot count } }
        actions { total asOf data { slot count } }
      }
    }
  }
`;

type Session = {
  user: User;
  expires: string;
};

type User = {
  id: string;
  email: string;
  name?: string;
  passwordChangedAt: string;
  createdAt: string;
};

type Account = {
  id: string;
  name: string | null;
  billingEmail: string;
  status: string;
  createdAt: string;
  updatedAt: string;

  identifier: {
    dsnPrefix: string;
    domain?: string;
    verifiedAt?: string;
  };

  plan: {
    name: string;
    features: {
      events: number;
      actions: number;
      campaigns: number;
      users: number;
      integrationBlacklist: string[];
    };
  };

  // TODO: typing
  onboarding?: any;
  useCases?: any;
  datadogKey?: { value: string };

  usage: {
    events: {
      total: number;
      asOf: string;
      data: { slot: string; count: number };
    };
    actions: {
      total: number;
      asOf: string;
      data: { slot: string; count: number };
    };
  };
};

type Response = {
  session?: Session;
  account: Account;
};

type ContextType = {
  data: Response | null;
  refetch: (opts?: any) => any;
};

const SelfContext = React.createContext<ContextType>({
  data: null,
  refetch: () => {},
});

export const SelfProvider: React.FC = ({ children }) => {
  const [{ fetching, data }, refetch] = useQuery<Response>({ query });
  const client = useLDClient();

  React.useEffect(() => {
    data && postSelf(data);

    // Initialize Launch Darkly w/ user data
    if (data && client && data.session) {
      client.identify(
        {
          key: data.session.user.id,
          name: data.session.user.name,
          email: data.session.user.email,
          custom: {
            accountId: data.account.id,
            accountName: data.account.name || "",
          },
        },
        undefined
      );
    }
  }, [data, client]);

  if (fetching) {
    return <Loading stretch />;
  }

  return (
    <SelfContext.Provider value={{ data: data || null, refetch }}>
      {children}
    </SelfContext.Provider>
  );
};

// postSelf runs when we have user information.
const postSelf = (data: Response) => {
  const id = data.session?.user?.id;
  if (!id || !data.session) {
    return;
  }

  if (globalThis.Inngest && globalThis.Inngest.identify) {
    globalThis.Inngest.identify(id, {
      email: data.session.user.email,
    });
  }

  // @ts-ignore: we don't care about chatwoot globals.
  if (!window.$chatwoot) {
    return;
  }
  // @ts-ignore: we don't care about chatwoot globals.
  window.$chatwoot.setUser(id, {
    email: data?.session.user.email,
  });
};

export const useSelf = (): ContextType => {
  return useContext(SelfContext);
};

export const useUpdateSetting = () => {
  return useMutation<
    { updateActionSetting: { id: string; name: string; value: string } },
    {
      name: string;
      value: string;
    }
  >(updateSetting);
};

const updateSetting = `
mutation UpdateSetting($name: String!, $value: String!) {
  updateAccountSetting(name: $name, value: $value) {
    id name value
  }
}
`;

export default useSelf;
