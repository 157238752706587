import styled from "@emotion/styled";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAsyncFn, useAsyncRetry } from "react-use";
import { API } from "src/consts";
import {
  useCreateVercelApp,
  useRemoveVercelApp,
  useUpdateVercelApp,
  useVercelApps,
} from "src/data/integrations/queries";
import { VercelApp } from "src/data/integrations/types";
import Button from "src/shared/Button";
import { Content } from "src/shared/Common";
import Check from "src/shared/Icons/Check";
import Logo from "src/shared/Icons/Logo";
import Refresh from "src/shared/Icons/Refresh";
import PageTitle from "src/shared/PageTitle";
import Table from "src/shared/Table";
import Pagination from "src/shared/Pagination";
import useToast from "src/shared/Toast";
import Toggle, { ToggleWrapper } from "src/shared/Toggle";
import { useProductionWorkspace } from "src/state/workspaces";
import { z } from "zod";

const projectsSchema = z.object({
  projects: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
    })
  ),
});

const queryStringSchema = z.object({
  code: z.string().default(""),
  next: z.string().default(""),
});

type Project = {
  enabledProject?: VercelApp | undefined;
  name: string;
  id: string;
  path: string;
  enabled: boolean;
};

export const VercelIntegration = () => {
  // This integration is alwayas installed for the production workspace
  const workspace = useProductionWorkspace();
  const { search } = useLocation();

  const { code, next } = React.useMemo(() => {
    return queryStringSchema.parse(queryString.parse(search));
  }, [search]);
  const [showInstallComplete, setShowInstallComplete] =
    useState<boolean>(false);

  const allVercelProjects = useAsyncRetry(async () => {
    const url = new URL("/v1/integrations/vercel/projects", API);
    url.searchParams.set("workspaceID", workspace.id);

    if (code && typeof code === "string") {
      url.searchParams.set("code", code);
    }

    const res = await fetch(url.href, {
      credentials: "include",
    });

    return projectsSchema.parse(await res.json());
  }, [code, workspace.id]);

  const [
    { data: enabledProjects, fetching: enabledProjectsLoading },
    refetchVercelApps,
  ] = useVercelApps();

  const [projectChanges, setProjectChanges] = React.useState<
    Record<string, { enabled?: boolean; path?: string }>
  >({});

  const projects = React.useMemo<Project[]>(() => {
    const enabledProjectsMap: Record<string, VercelApp> =
      enabledProjects?.workspace?.vercelApps?.reduce((acc, vercelApp) => {
        return {
          ...acc,
          [vercelApp.projectID]: vercelApp,
        };
      }, {}) || {};

    return (
      allVercelProjects.value?.projects.map((project) => {
        return {
          ...project,
          ...(enabledProjectsMap[project.id]
            ? {
                enabledProject: enabledProjectsMap[project.id],
                enabled: projectChanges[project.id]?.enabled ?? true,
                path:
                  projectChanges[project.id]?.path ??
                  enabledProjectsMap[project.id].path,
              }
            : {
                enabled: projectChanges[project.id]?.enabled ?? false,
                path: projectChanges[project.id]?.path ?? "",
              }),
        };
      }) || []
    ).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }, [allVercelProjects.value, enabledProjects, projectChanges]);

  const loading = React.useMemo<boolean>(() => {
    return (
      (!allVercelProjects.value || !enabledProjects) &&
      (allVercelProjects.loading || enabledProjectsLoading)
    );
  }, [
    allVercelProjects.value,
    allVercelProjects.loading,
    enabledProjects,
    enabledProjectsLoading,
  ]);

  const isPopup = React.useMemo(() => Boolean(code), [code]);

  useEffect(() => {
    if (isPopup) {
      document.body.style.overflow = "hidden";
    }
  }, [isPopup]);

  const [, createVercelApp] = useCreateVercelApp();
  const [, updateVercelApp] = useUpdateVercelApp();
  const [, removeVercelApp] = useRemoveVercelApp();
  const toast = useToast();

  const [savingState, saveConfig] = useAsyncFn(async () => {
    const actions = projects.reduce<Promise<any>[]>((acc, project) => {
      if (project.enabledProject && !project.enabled) {
        return [...acc, removeVercelApp({ projectID: project.id })];
      }

      if (!project.enabledProject && project.enabled) {
        return [
          ...acc,
          createVercelApp({
            projectID: project.id,
            path: project.path,
          }),
        ];
      }

      if (
        project.enabledProject &&
        project.enabled &&
        project.path !== project.enabledProject.path
      ) {
        return [
          ...acc,
          updateVercelApp({
            projectID: project.id,
            path: project.path,
          }),
        ];
      }

      return acc;
    }, []);

    try {
      await Promise.all(actions);

      toast.push({
        type: "success",
        message: "Saved Vercel configuration successfully",
      });

      refetchVercelApps();
    } catch (err) {
      console.error("Error when saving Vercel config", err);

      toast.push({
        type: "error",
        message: "Error saving Vercel config; try again later",
      });
    }

    if (next) {
      setShowInstallComplete(true);
    }
  }, [projects, createVercelApp, updateVercelApp, removeVercelApp, next]);

  const redirectToVercel = () => {
    window.location.href = next;
  };

  const [hasRunAutoSelect, setHasRunAutoSelect] = React.useState(false);

  React.useEffect(() => {
    if (hasRunAutoSelect || projects.length === 0) return;
    setHasRunAutoSelect(true);

    /**
     * Only auto-select a project if it's the only one available and we're in
     * the initial adding flow.
     */
    if (projects.length !== 1 || !isPopup) return;

    const firstProjectId = projects[0].id;

    setProjectChanges((prev) => ({
      ...prev,
      [firstProjectId]: {
        ...prev[firstProjectId],
        enabled: true,
      },
    }));
  }, [hasRunAutoSelect, projects, isPopup]);

  const rowFn = React.useCallback(
    ({ item }: { item: Project }) => (
      <ProjectRow
        key={item.id}
        project={item}
        enabled={item.enabled}
        path={item.path}
        onChangeEnabled={(enabled) => {
          setProjectChanges((changes) => {
            return {
              ...changes,
              [item.id]: {
                ...changes[item.id],
                enabled,
              },
            };
          });
        }}
        onChangePath={(path) => {
          setProjectChanges((changes) => {
            return {
              ...changes,
              [item.id]: {
                ...changes[item.id],
                path,
              },
            };
          });
        }}
      />
    ),
    []
  );

  // Pagination
  const PROJECTS_PER_PAGE = 6; // for Vercel's tiny pop up window
  const totalItems = projects.length;
  const totalPages = Math.ceil(totalItems / PROJECTS_PER_PAGE);
  const [page, setPage] = useState<number>(0);
  const visibleProjects = projects.slice(
    page * PROJECTS_PER_PAGE,
    (page + 1) * PROJECTS_PER_PAGE
  );

  if (showInstallComplete) {
    const projectCount = projects.filter((p) => p.enabled).length;
    return (
      <Container popup={isPopup}>
        <div>
          <Logo />
        </div>
        <div
          style={{
            display: "flex",
            gap: "0.8rem",
            margin: "1rem 0",
            alignItems: "center",
          }}
        >
          <Check size="20" color="#1F9A4C" />{" "}
          <p style={{ fontSize: "1rem" }}>
            The Inngest Integration has successfully been installed for{" "}
            {projectCount} projects!
          </p>
        </div>
        <Content popup={isPopup}>
          <h2 style={{ marginTop: "2rem" }}>What has been set up:</h2>
          <ol
            style={{
              paddingLeft: "2rem",
              margin: "1rem 0 2rem",
              fontSize: "1rem",
            }}
          >
            <li>
              Each Vercel project will have <code>INNGEST_SIGNING_KEY</code> and{" "}
              <code>INNGEST_EVENT_KEY</code> environment variables set
            </li>
            <li>
              The next time you deploy your project to Vercel your functions
              will automatically appear in the Inngest dashboard
            </li>
          </ol>
          <Button onClick={redirectToVercel}>Continue to Vercel →</Button>
        </Content>
      </Container>
    );
  }

  return (
    <Container popup={isPopup}>
      {isPopup ? (
        <>
          <div>
            <Logo />
          </div>
          <p>
            Let's connect your Vercel account to Inngest! Select which Vercel
            projects to enable.
          </p>
          <h2 style={{ marginTop: "1rem" }}>Projects</h2>
        </>
      ) : (
        <PageTitle>
          <div>
            <h1>Projects</h1>
          </div>
        </PageTitle>
      )}

      <Content popup={isPopup}>
        <div>
          Click "Enable" for each project that you have Inngest functions. You
          can optionally specify a custom serve route (
          <a
            href="https://www.inngest.com/docs"
            target="_blank"
            rel="noreferrer"
          >
            see docs
          </a>
          ) other than the default.
          {isPopup
            ? " You can always change this in the Inngest settings."
            : ""}
        </div>
        <div style={{ margin: "1rem 0" }}>
          <Table
            columns={[
              { name: "Enabled", width: "64px" },
              { name: "Name", width: "1fr" },
              { name: "Serve Route", width: "1fr" },
            ]}
            loading={loading}
            data={visibleProjects}
            keyFn={(p) => p.id}
            row={rowFn}
            blank={BlankProjectList}
          />
          {totalPages > 1 && (
            <Pagination
              onChange={(p) => {
                setPage(p - 1);
              }}
              page={{ page: page + 1, totalPages, totalItems }}
              style={{ marginTop: "1rem" }}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: isPopup ? "normal" : "space-between",
          }}
        >
          {isPopup ? (
            <div style={{ flex: 1 }}>
              Once saved, Inngest will automatically configure your functions to
              be run whenever you deploy to Vercel. More info on{" "}
              <a
                href="https://www.inngest.com/docs"
                target="_blank"
                rel="noreferrer"
              >
                the Vercel integration here
              </a>
              .
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                onClick={async () => {
                  await refetchVercelApps();
                  toast.push({
                    type: "default",
                    message: "Refreshed Vercel projects",
                  });
                }}
                loading={enabledProjectsLoading}
              >
                <Refresh size={15} /> Refresh project list
              </Button>
              <Button
                link="https://vercel.com/integrations/inngest"
                style={{ marginLeft: "1rem" }}
              >
                <img
                  src="/assets/source-logos/vercel-icon-light.svg"
                  style={{ height: "0.7rem" }}
                  alt="Vercel logo icon"
                />
                Go to Vercel
              </Button>
            </div>
          )}
          <Button
            kind="primary"
            style={{ marginLeft: "1rem" }}
            onClick={() => saveConfig()}
            disabled={!projects.length}
            loading={savingState.loading}
          >
            Save configuration
          </Button>
        </div>
      </Content>
    </Container>
  );
};

const BlankProjectList = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
        maxWidth: "70%",
      }}
    >
      <h3>No projects found</h3>
      <p>
        Looks like we couldn't find any projects on your Vercel account. Your
        session may have expired or something else went wrong.
      </p>
      <p style={{ marginTop: "0.5rem" }}>
        Try closing this window and re-adding the integration.
      </p>
    </div>
  );
};

interface ProjectRowProps {
  project: Project;
  onChangeEnabled: (enabled: boolean) => void;
  onChangePath: (path: string) => void;
  path: string;
  enabled: boolean;
}

const ProjectRow = ({
  project: { id, name },
  path,
  enabled,
  onChangeEnabled,
  onChangePath,
}: ProjectRowProps) => {
  const shownPath = React.useMemo(() => {
    return path === "/api/inngest" ? undefined : path || undefined;
  }, [path]);

  return (
    <div>
      <div style={{ overflow: "visible" }}>
        <ToggleWrapper
          onClick={() => {
            onChangeEnabled(!enabled);
          }}
        >
          <Toggle defaultChecked={enabled} />
        </ToggleWrapper>
      </div>
      <div>{name}</div>
      <ServeInput
        key={`${id}-input`}
        type="text"
        placeholder="/api/inngest (default)"
        value={shownPath || ""}
        onChange={(e) => onChangePath(e.target.value)}
      />
    </div>
  );
};

const Container = styled.div<{ popup?: boolean }>`
  ${(props) =>
    props.popup
      ? `position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: scroll;
        background: var(--bg);
        padding: 2rem;`
      : ""}
`;

const ServeInput = styled.input`
  flex: 1;
`;
