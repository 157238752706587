import React, { useContext, useState, useEffect } from "react";
import { useLocalStorage } from "react-use";
import Loading from "src/shared/Loading";
import { useMutation, useQuery } from "urql";
import history from "src/utils/history";
import { useLocation } from "react-router-dom";
import { Workspace } from "src/types";
import { POST_LOGIN_REDIRECT_KEY } from "src/consts";

type Response = {
  workspaces: Workspace[];
};

type ContextType = {
  data: Response | null;
  name?: string;
  test: boolean;
  refetch: (opts?: any) => any;
  setTest: (b: boolean) => void;
  setName: (t: string) => void;
};

const WorkspaceContext = React.createContext<ContextType>({
  data: null,
  test: false,
  refetch: () => {},
  setTest: (_: boolean) => {},
  setName: (_: string) => {},
});

export const WorkspaceProvider: React.FC<{
  isTest: boolean;
  setTest: (to: boolean) => void;
}> = ({ children, isTest, setTest }) => {
  const [{ fetching, data }, refetch] = useQuery<Response>({ query });
  const [name, setName] = useState<string>();

  if (fetching && !data) {
    return <Loading stretch />;
  }

  return (
    <WorkspaceContext.Provider
      value={{
        data: data || null,
        refetch,
        name,
        test: isTest,
        setTest,
        setName,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspaces = (): ContextType => {
  return useContext(WorkspaceContext);
};

export const useCurrentWorkspace = (): Workspace => {
  const { data, name, test, setTest } = useWorkspaces();
  const [postLoginRedirect, setPostLoginRedirect] = useLocalStorage<string>(
    POST_LOGIN_REDIRECT_KEY
  );
  const location = useLocation();

  const workspaces = data?.workspaces
    ? data.workspaces.filter((w) =>
        !name ? w.name === "default" : w.name === name
      )
    : [];
  const result = workspaces.find((w) => w.test === test);

  useEffect(() => {
    if (!result) {
      console.warn("unable to find workspace");
      setTest(false);
      history.push("/login");
      // This won't be hit as we change location.pathname, but serves to ensure
      // that we always return a Workspace from this function to satisfy typescript.
    }
  }, [result]);

  // Handle post-login redirects if the server side cookie-based method fails
  useEffect(() => {
    if (postLoginRedirect?.length) {
      if (location.pathname === "/") {
        history.push(postLoginRedirect);
      }
      setPostLoginRedirect("");
    }
  }, [location, postLoginRedirect, setPostLoginRedirect]);

  // @ts-ignore
  return result || {};
};

const query = `
query Workspaces {
  workspaces {
    id name test webhookSigningKey
    integrations { name service events actions webhookEndpoints }
  }
}
`;

/**
 * Get the production workspace for non-test UI actions
 * @returns
 */
export const useProductionWorkspace = (): Workspace => {
  const { data, name } = useWorkspaces();
  const workspaces = data
    ? data.workspaces.filter((w) =>
        !name ? w.name === "default" : w.name === name
      )
    : [];
  const result = workspaces.find((w) => w.test === false);
  // @ts-ignore
  return result;
};

export const useNewWorkspace = () => {
  const [, execute] = useMutation<
    { createWorkspace: Workspace[] },
    { input: { name: string } }
  >(newWorkspaceGQL);
  return execute;
};

const newWorkspaceGQL = `
mutation NewWorkspace($input: NewWorkspaceInput!) {
  createWorkspace(input: $input) {
    id name test
    integrations { name service events actions webhookEndpoints }
  }
}
`;
