import { useCurrentWorkspace } from "src/state/workspaces";
import { useQuery } from "urql";

export type SearchResult = {
  object: string;
  field?: string;
  count: number;
  results: Array<{ [field: string]: any }>;
};

export const useSearch = (object: string, term: string, field?: string) => {
  const w = useCurrentWorkspace();
  return useQuery<{ w: { search: SearchResult[] } }, {}>({
    query: searchGQL,
    variables: {
      workspaceID: w.id,
      input: {
        object,
        term,
        field,
      },
    },
    pause: true,
  });
};

const searchGQL = `
  query Search($workspaceID: ID!, $input: SearchInput!) {
    w: workspace(id: $workspaceID) {
    search(input: $input) {
      object
      field
      count
      results
      }
    }
  }
`;
