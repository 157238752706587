import { useQuery, useMutation } from "urql";

export type APIKey = {
  id: string;
  name: string | undefined;
  createdAt: string;
  author: {
    email: string;
    name: string | undefined;
  };
};

export type Webhook = {
  id: string;
  name: string;
  url: string;
  presharedKey: string;
  enabled: boolean;
  allEvents: boolean;
  events: string[];
  createdAt: string;
  author: {
    email: string;
    name: string | undefined;
  };
};

export type VisibleAPIKey = APIKey & {
  private: string;
};

export const useData = () => {
  return useQuery<{ apiKeys: APIKey[]; webhooks: Webhook[] }>({
    query: `
      query APIWebhook {
        webhooks {
          id name url createdAt presharedKey enabled allEvents events
          author {
            email
            name
          }
        }
        apiKeys {
          id name createdAt
          author {
            email
            name
          }
        }
      }
      `,
  });
};

export const useCreateAPIKey = () => {
  const [, execute] = useMutation<
    { createAPIKey: VisibleAPIKey },
    { name?: string }
  >(`
    mutation CreateAPIKey($name: String) {
      createAPIKey(name: $name) {
        id name private createdAt
        author {
          email
          name
        }
      }
    }
  `);
  return execute;
};

export const useCreateWebhook = () => {
  const [, execute] = useMutation<
    { createWebhook: Webhook },
    {
      input: {
        name: string;
        url: string;
        events: string[];
        allEvents: boolean;
      };
    }
  >(`
    mutation CreateWebhook($input: NewWebhook!) {
      createWebhook(input: $input) {
        id name url createdAt presharedKey enabled allEvents events
        author {
          email
          name
        }
      }
    }
  `);
  return execute;
};
