import { useState } from "react";

// Stripe should be a singleton.
let instance = null;

const stripe = () => {
  if (instance !== null) {
    return instance;
  }
  instance =
    window.Stripe &&
    window.Stripe(
      process.env.REACT_APP_STRIPE_KEY ||
        "pk_test_51IVczWJKEym2H9VglQOhGv5vyUr9JHopj0RxoTrLcTUTU0j9MOpxF9TCewn4ZLvJAPjMbM3wiW28MniorA3bW7Be00OpBYYzau"
    );
  return instance;
};

export default stripe;
