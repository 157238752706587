import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useFormState } from "react-use-form-state";
import useToast from "src/shared/Toast";
import Logo from "src/shared/Icons/Logo";
import Box from "src/shared/Box";
import useSelf from "src/state/self";
import Button, { ButtonGroup } from "src/shared/Button";
import { apiURL } from "src/utils";
import BG from "./BG";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const { push } = useToast();
  const history = useHistory();
  const { data, refetch } = useSelf();
  const [formState, { email, text, password }] = useFormState();

  useEffect(() => {
    if (data && data.session?.user.id) {
      history.replace("/");
    }
  }, [data]);

  const disabled = !formState.values.email || !formState.values.password;

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    const result = await fetch(
      `${process.env.REACT_APP_API_HOST}/v1/register`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(Object.assign({}, formState.values)),
      }
    );

    if (result.status !== 200) {
      setLoading(false);
      try {
        const json = await result.json();
        push({ type: "error", message: json.error });
      } catch (e) {
        push({
          type: "error",
          message: "There was an error signing you up",
        });
      }
      return;
    }

    refetch();
    push({ type: "success", message: "Welcome to your new account!" });
    setLoading(false);
    history.push("/");
  };

  return (
    <BG>
      <Grid>
        <a href="/">
          <Logo width={100} fill="#fff" />
        </a>
        <div />
        <Wrapper>
          <Oauth>
            <Button link={apiURL("/v1/login/oauth/github/redirect")}>
              <img src="/assets/gh-mark.png" alt="GitHub" width="20" />
              <span>
                Register with <b>GitHub</b>
              </span>
            </Button>

            <Button link={apiURL("/v1/login/oauth/google/redirect")}>
              <img src="/assets/icons/google.svg" alt="Google" width="20" />
              <span>
                Register with <b>Google</b>
              </span>
            </Button>
          </Oauth>

          <h1>Create a free account</h1>
          <p>
            Already have an account? <Link to="/login">Log in</Link>
          </p>

          <form onSubmit={handleSubmit}>
            <label>
              Work email
              <input {...email("email")} placeholder="you@company.com" />
            </label>

            <label>
              Password
              <input
                {...password("password")}
                placeholder="Create a strong password"
              />
            </label>

            <ButtonGroup stretch style={{ paddingTop: 30 }}>
              <Button
                kind="primary"
                type="submit"
                disabled={disabled || loading}
                onClick={handleSubmit}
              >
                Register
              </Button>
            </ButtonGroup>
          </form>
        </Wrapper>
      </Grid>
    </BG>
  );
};

const Oauth = styled.div`
  max-width: 400px;
  min-width: 300px;
  margin: 0 auto;
  padding: 1rem 0 2rem;
  margin: 0 auto 2rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  border-bottom: 1px solid var(--border-color);


  .button {
    text-align: center
    display: flex;
    justify-content: center;
  }

  .button + .button {
    margin: 1rem 0 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 20px 40px;
  grid-template-columns: 1fr;
  min-width: 800px;
  margin-bottom: 20px;
  justify-content: center;

  > div:first-of-type {
    display: flex;
    justify-content: center;
  }

  > a:first-of-type {
    display: block;
    text-align: center;
  }
`;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  h1 {
    text-align: center;
    font-weight: normal;
    margin: 0;

    & + p {
      margin: 0 0 40px;
      opacity: 0.5;
      text-align: center;
      font-size: 14px;
    }
  }
`;

export default Register;
