import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Box from "src/shared/Box";
import Logo from "src/shared/Icons/Logo";
import styled from "@emotion/styled";
import Button from "src/shared/Button";
import useToast from "src/shared/Toast";
// local
import BG from "./BG";

export default () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { push } = useToast();

  // Get the "redirect url"
  const location = useLocation();
  const clientID = new URLSearchParams(location.search).get("client_id") || "";
  const [code, setCode] = useState(
    new URLSearchParams(location.search).get("user_code") || ""
  );
  const [done, setDone] = useState(false);
  const disabled = !code;

  const handleSubmit = async (e: React.SyntheticEvent) => {
    setLoading(true);
    e.preventDefault();
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_HOST}/v1/login/device/confirm`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            client_id: clientID,
            user_code: code,
          }),
        }
      );
      setLoading(false);

      const data = await result.json();
      if (result.status !== 200) {
        push({ type: "error", message: data.error || "Invalid code" });
        return;
      }

      push({ type: "success", message: "Device authorized" });
      setDone(true);
    } catch (err) {
      setLoading(false);
      push({
        type: "error",
        message: "Could not reach the Inngest API, please try again",
        duration: 50000,
      });
    }
  };

  return (
    <BG>
      <Logo width={150} fill="#fff" />
      <Heading>Authorize your CLI</Heading>
      <Wrapper>
        {done ? (
          <Inner>
            <h3>Login complete</h3>
            <p>You can close this tab, or continue to the Inngest app.</p>
            <Button type="submit" link="/" kind={"outlineWhite"}>
              Go to the app
            </Button>
          </Inner>
        ) : (
          <Form onSubmit={handleSubmit}>
            <p>To sign in, enter the code provided by your app:</p>
            <label>
              Code
              <input
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Code"
              />
            </label>
            <Button
              type="submit"
              size="large"
              disabled={disabled}
              loading={loading}
              kind={disabled ? undefined : "primary"}
            >
              Authorize
            </Button>
          </Form>
        )}
      </Wrapper>
    </BG>
  );
};

const Wrapper = styled(Box)`
  background: var(--bg-alt);

  .button {
    width: 100%;
    justify-content: center;
  }

  .button + .button {
    margin-top: 1rem;
  }
`;

const Inner = styled.div`
  width: 400px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-weight: 600;
  }
  a {
    margin: 2rem 0 0;
  }
`;

const Form = styled.form`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  position: relative;

  p {
    text-align: center;
    margin: 1rem 0 2rem;
  }

  button {
    width: 100%;
    text-align: center;
    display: block;
    margin-top: 30px;
  }
`;

const Heading = styled.h2`
  margin: 0;
  padding: 0 0 50px;
  text-align: center;
  color: #fff;
  font-size: 1.2rem;
`;
