import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import Top, { Actions } from "src/shared/TopHeader";
import { PageTitle, Content } from "src/shared/Common";
import Breadcrumbs from "src/shared/Breadcrumbs";
import Button from "src/shared/Button";
import Box from "src/shared/Box";
import { diffNow } from "src/utils/time";
import UpgradeBox from "src/shared/UpgradeBox";
import Modal from "src/shared/Modal";
import useSelf from "src/state/self";
import AdminTabs from "../AdminTabs";
import NewUser from "./NewUser";
import { useUsers, User } from "./query";
import Table from "src/shared/Table";

const Users: React.FC = () => {
  const { data: selfData } = useSelf();
  const [{ data, fetching }] = useUsers();
  const [showModal, setShowModal] = useState(false);

  const users = (data ? data.account.users : []).sort((a, b) =>
    a.createdAt.localeCompare(b.createdAt)
  );

  const atCapacity =
    selfData && data
      ? selfData.account.plan.features.users <= data.account.users.length
      : false;

  return (
    <div>
      <PageTitle>
        <div>
          <span>Settings</span>
          <h1>Users</h1>
        </div>
        <div>
          {!atCapacity && (
            <Button kind="primary" onClick={() => setShowModal(true)}>
              Add user
            </Button>
          )}
        </div>
      </PageTitle>

      <Content>
        <AdminTabs />

        {atCapacity && (
          <UpgradeBox
            style={{ margin: "0 auto", maxWidth: 800 }}
            title="Need more users?"
            text={
              <p>
                You{"'"}re at the limit for your current plan.
                <br />
                To add more users head over and change your plan.
              </p>
            }
          />
        )}

        <Table<User>
          columns={[
            { name: "Name & Email", width: "3fr" },
            { name: "Added", width: "1fr" },
            { name: "Last login", width: "1fr" },
          ]}
          loading={fetching}
          data={users}
          keyFn={(u) => u.id}
          row={({ item }) => (
            <div>
              <Name>
                <p>{item.name || <span>(no name)</span>}</p>
                <small>{item.email}</small>
              </Name>
              <div>{DateTime.fromISO(item.createdAt).toFormat("D, t")}</div>
              <div>
                <p>
                  {item.lastLoginAt
                    ? DateTime.fromISO(item.lastLoginAt).toFormat("D, t")
                    : "-"}
                </p>
                <small>{item.lastLoginAt && diffNow(item.lastLoginAt)}</small>
              </div>
            </div>
          )}
        />
      </Content>

      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <NewUser onComplete={() => setShowModal(false)} />
        </Modal>
      )}
    </div>
  );
};

const Header = styled.div`
  background: #fafafa;
  padding: 16px 24px !important;
  font-weight: 500;
  color: #666;
`;

const Grid = styled.div`
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  font-size: 14px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 24px;
    border-bottom: 1px solid #f5f5f5;
  }

  small {
    opacity: 0.7;
  }
`;

const Name = styled.div`
  p:first-of-type {
    font-weight: 500;
  }
  p:last-of-type {
    opacity: 0.7;
  }
`;

export default Users;
