import React from "react";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

export const Tabs: React.FC<{
  padding?: boolean;
  style?: any;
  css?: any;
  className?: any;
}> = ({ padding, children, style, css, className }) => {
  return (
    <TabsWrapper
      style={style}
      css={[css, padding && paddingCSS]}
      className={className}
    >
      {children}
    </TabsWrapper>
  );
};

const paddingCSS = css`
  padding: 0 40px;
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  border-bottom: 1px solid var(--border-color-focus);
  font-size: 16px;
  line-height: 18px;
  position: relative;
  z-index: 2;

  > a {
    position: relative;
    bottom: -1px;
    height: calc(18px + 2 * 8px);
    padding: 8px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid transparent;
    color: var(--color-dim);
    text-decoration: none;
    transition: all 0.3s;

    &:hover {
      color: var(--color);
    }
  }
  a + a {
    margin-left: 48px;
  }

  a.active {
    z-index: 3;
    color: var(--color);
    border-bottom: 1px solid var(--color);
  }
`;

type TabProps = {
  to?: string;
  active?: boolean;
  onClick?: () => void;
};

export const Tab: React.FC<TabProps> = ({ to, onClick, active, children }) => {
  if (to) {
    return (
      <NavLink to={to} exact>
        {children}
      </NavLink>
    );
  }
  return (
    <a onClick={onClick} className={active ? "active" : ""}>
      {children}
    </a>
  );
};
