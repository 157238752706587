import styled from "@emotion/styled";

const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 13px 0;
  font-size: 13px;
`;

export const Actions = styled.div`
  display: flex;
`;

export default TopHeader;
