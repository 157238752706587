import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import useToast from "src/shared/Toast";
import { PageTitle, Content } from "src/shared/Common";
import AdminTabs from "../AdminTabs";
import { useCurrentWorkspace, useWorkspaces } from "src/state/workspaces";
import Loading from "src/shared/Loading";
import Button from "src/shared/Button";

const Slack = () => {
  const workspace = useCurrentWorkspace();
  const { refetch } = useWorkspaces();

  const [isLoading, setIsLoading] = useState(true);
  const isSignedIn = !!workspace.integrations.find((w) => w.name === "slack");

  const { search } = useLocation();
  const browserHistory = useHistory();
  const code = queryString.parse(search).code;

  const setAccessToken = async (code: string) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_HOST}/v1/integrations/slack`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          code,
          workspaceID: workspace.id,
        }),
      }
    );

    refetch();
    setIsLoading(false);
  };

  useEffect(() => {
    if (!code) {
      setIsLoading(false);
      return;
    }

    setAccessToken(code as string);
    browserHistory.push("/settings/slack");
  }, [code]);

  return (
    <>
      <PageTitle>
        <div>
          <span>Settings</span>
          <h1>Slack</h1>
        </div>
      </PageTitle>
      <Content>
        <AdminTabs />
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            {isSignedIn ? (
              <>
                <div>You are signed into Slack!</div>&nbsp;&nbsp;
                <SignOut workspaceID={workspace.id} refetch={refetch} />
              </>
            ) : (
              <SignIn />
            )}
          </div>
        )}
      </Content>
    </>
  );
};

const REDIRECT_URI = `${process.env.REACT_APP_FQDN_APP}/settings/slack/success`;
const SCOPE =
  "chat:write.public,channels:read,chat:write,groups:read,im:read,im:write";

const SignOut = ({
  workspaceID,
  refetch,
}: {
  workspaceID: string;
  refetch: () => void;
}) => {
  const { push } = useToast();

  const onSignOut = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/v1/integrations/slack/logout`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          workspaceID,
        }),
      }
    );

    const result = await response.json();
    if (result.error) {
      push({
        type: "error",
        message: "There was an error.",
      });
      return;
    }

    refetch();
  };

  return (
    <Button onClick={onSignOut} kind="link">
      Sign out
    </Button>
  );
};

const SignIn = () => {
  return (
    <a
      href={`https://slack.com/oauth/v2/authorize?scope=${SCOPE}&client_id=1723003321891.1728987664660&redirect_uri=${REDIRECT_URI}`}
    >
      <img
        alt="Sign in with Slack"
        height="40"
        width="172"
        src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
        srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
      />
    </a>
  );
};

export default Slack;
