import { Tab, Tabs } from "src/shared/Tabs";

const AdminTabs = () => (
  <Tabs style={{ marginBottom: 40 }}>
    <Tab to={`/settings`}>Your account</Tab>
    <Tab to={`/settings/org`}>Organization</Tab>
    <Tab to={`/settings/billing`}>Billing</Tab>
    <Tab to={`/settings/users`}>Users</Tab>
  </Tabs>
);

export default AdminTabs;
