import { useState, createRef, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { useCurrentWorkspace } from "src/state/workspaces";
import SearchIcon from "src/shared/Icons/Search";
import { Dropdown } from "src/shared/Dropdown/Dropdown";
import { useSearch, SearchResult } from "./query";
import Search from "../Search/Search";
import Tag from "../Tag";
import css from "@emotion/css";
import { defaultTimeFormat } from "src/utils/time";
import { useHistory } from "react-router-dom";

export const GlobalSearch = () => {
  const w = useCurrentWorkspace();
  const ref = createRef<HTMLInputElement>();
  const [object, setObject] = useState("all");
  const [search, setSearch] = useState("");
  const [focus, setFocus] = useState(false);
  const [data, execute] = useSearch(object, search);

  useEffect(() => {
    if (search !== "") {
      execute({
        workspaceID: w.id,
        input: { object, term: search },
      });
    }
  }, [search, object]);

  // Format the results.
  const results = useMemo(() => {
    if (!data.data) {
      return [];
    }
    return (data.data.w.search || [])
      .map(mapSearchResult)
      .filter((r) => r.results.length > 0);
  }, [JSON.stringify(data.data)]);

  return (
    <Wrapper
      className={focus ? "focus" : ""}
      onClick={() => ref?.current?.focus()}
    >
      <span>Search...</span>
      {/* @ts-ignore */}
      <Dropdown
        onChange={(o) => {
          setObject(o.value);
          ref?.current?.focus();
        }}
        onFocus={() => setFocus(true)}
        value={object}
        options={[
          { value: "all", label: "Everything" },
          { value: "event", label: "Events" },
          { value: "workflow", label: "Workflows" },
        ]}
      />

      <SearchDiv>
        <SearchIcon style={{ opacity: 0.5 }} />
        <Search
          style={{
            boxShadow: "none",
            border: "none",
            background: "transparent",
          }}
          icon={false}
          ref={ref}
          defaultValue={search}
          onChange={(e) => setSearch(e)}
          onBlur={() => setFocus(false)}
          onFocus={() => setFocus(true)}
          placeholder={`Search your ${
            object === "all" ? "workspace" : object + "s"
          }...`}
          loading={data.fetching ? "Searching..." : undefined}
          results={results}
          resultsCSS={resultsCSS}
          onSelect={() => {}}
        />
      </SearchDiv>
    </Wrapper>
  );
};

const mapSearchResult = (r: SearchResult) => {
  const title = (() => {
    switch (r.object) {
      case "event":
        if (r.field === "name") {
          return "Events by name";
        }
        if (r.field === "event") {
          return "Events by content";
        }
        break;
      case "workflow":
        return "Workflows";
    }
    return r.object;
  })();

  return {
    title,
    results: r.results.map((item) => mapSearchItem(r, item)),
  };
};

const mapSearchItem = (r: SearchResult, item: { [k: string]: any }) => {
  if (r.object === "event") {
    switch (r.field) {
      case "name": {
        const href = `/events/e/${encodeURIComponent(item.name)}`;
        return {
          value: encodeURIComponent(item.name),
          data: item.name,
          render: ({ className }: { className?: string }) => {
            return (
              <Link to={href} className={className}>
                {item.name}
              </Link>
            );
          },
        };
      }
      case "event": {
        const href = `/events/id/${item.id}`;
        return {
          value: encodeURIComponent(item.name),
          data: item.name,
          render: ({ className }: { className?: string }) => {
            return (
              <EventLink to={href} className={className}>
                <span>{item.name}</span>
                <Tag kinds={["identifier", "grey"]}>{item.id}</Tag>
                <span>{defaultTimeFormat(item.received_at)}</span>
              </EventLink>
            );
          },
        };
      }
    }
  }

  if (r.object === "workflow") {
    const href = `/workflows/${item.id}`;
    return {
      value: encodeURIComponent(item.name),
      data: item.name,
      render: ({ className }: { className?: string }) => {
        return (
          <Link to={href} className={className}>
            <span>{item.name}</span>
          </Link>
        );
      },
    };
  }

  return {
    value: "",
    data: "",
  };
};

const Wrapper = styled.div`
  /* 100% height of top bar */

  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  padding: 0 20px;
  border-radius: 2px;
  transition: all 0.3s;

  span {
    display: block;
    opacity: 0.5;
    margin: 0 0 -2px;
  }

  &.focus,
  &:focus {
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03),
      0 2px 10px rgba(0, 0, 0, 0.08);
  }

  .Dropdown-root {
    width: 141px;
  }

  .Dropdown-control {
    margin: 0 0 -2px 10px;
    border: 0;
  }

  .Dropdown-arrow {
    display: none;
  }
`;

const EventLink = styled(Link)`
  display: flex;

  > span:first-of-type {
    margin: 0 2rem 0 0;
    min-width: 100px;
  }
  > span:last-of-type {
    margin: 0 0 0 2.5rem;
    font-size: 12px;
    min-width: 100px;
  }
`;

const resultsCSS = css`
  padding: 0 0 0.5rem;

  .none,
  .group-title {
    font-size: 10px;
    padding: 1rem 1rem 0.25rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    opacity: 0.6;
    display: block;
  }

  .none {
    padding: 1rem 1rem 0.5rem;
  }

  .result {
    border-radius: 0;
    padding: 0.25rem 1rem;
  }

  a {
    text-decoration: none;
  }

  .result.selected {
    background: transparent;
    font-weight: 600;
    color: inherit !important;
  }

  .link {
    display: block;
  }
`;

const SearchDiv = styled.div`
  align-self: stretch;
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  border-left: 1px solid var(--border-color);

  svg {
    position: absolute;
    left: 15px;
    top: 50%;
    margin-top: -6px;
  }

  input {
    border: 0 !important;
    padding-left: 45px;
    background: transparent;
    box-shadow: none !important;
  }
`;
