// Source coolicons.cool
import React from "react";
import type { IconProps } from "./props";

const SystemData = ({ size = "16", fill = "currentColor" }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 11.3333L4 4.66666M12 4.66666L12 11.3333"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 11.3333C12 12.4379 10.2091 13.3333 8 13.3333C5.79086 13.3333 4 12.4379 4 11.3333"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 8C12 9.10457 10.2091 10 8 10C5.79086 10 4 9.10457 4 8"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 6.66666C10.2091 6.66666 12 5.77123 12 4.66666C12 3.56209 10.2091 2.66666 8 2.66666C5.79086 2.66666 4 3.56209 4 4.66666C4 5.77123 5.79086 6.66666 8 6.66666Z"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SystemData;
