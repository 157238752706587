import React, { useState, useMemo } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import Top, { Actions } from "src/shared/TopHeader";
import Breadcrumbs from "src/shared/Breadcrumbs";
import Button from "src/shared/Button";
import PageTitle from "src/shared/PageTitle";
import Box from "src/shared/Box";
import { diffNow } from "src/utils/time";
import Modal from "src/shared/Modal";
import AdminTabs from "../AdminTabs";
import { useData } from "./query";
import NewAPIKey from "./NewAPIKey";
import NewWebhook from "./NewWebhook";

type ModalState = "" | "api" | "webhook";

const APIWebhook: React.FC = () => {
  const [modal, setModal] = useState<ModalState>("");
  const [{ data }, refetch] = useData();

  const modalComponent = useMemo(() => {
    switch (modal) {
      case "webhook":
        return (
          <NewWebhook
            onComplete={() => {
              setModal("");
              refetch();
            }}
          />
        );
      case "api":
        return (
          <NewAPIKey
            onComplete={() => {
              setModal("");
              // ensure the cache is cleared;  if you have no API keys and create one it
              // won't show as the return type is different
              refetch();
            }}
          />
        );
      default:
        return null;
    }
  }, [modal, refetch]);

  return (
    <>
      <Top>
        <Breadcrumbs>
          <Link to="/admin">Admin</Link>
          <Link to="/admin/api">API & Webhooks</Link>
        </Breadcrumbs>

        <Actions>
          <Button kind="link" link="/">
            Back to campaigns
          </Button>
        </Actions>
      </Top>

      <AdminTabs />

      <PageTitle>
        <h1>API & Webhooks</h1>
      </PageTitle>

      <Grid>
        <Box
          title="API keys"
          action="New API key"
          onAction={() => setModal("api")}
        >
          <APIGrid>
            <Header>Key name</Header>
            <Header>Author</Header>
            <Header>Created at</Header>
            {data &&
              data.apiKeys.map((key) => (
                <>
                  <div>{key.name || <small>No name</small>}</div>
                  <div>{key.author.email}</div>
                  <div>
                    {diffNow(key.createdAt).split("(")[0]}
                    <small>({diffNow(key.createdAt).split("(")[1]}</small>
                  </div>
                </>
              ))}
          </APIGrid>
        </Box>
        <Box
          title="Webhooks"
          action="New webhook"
          onAction={() => setModal("webhook")}
        >
          <WebhookGrid>
            <Header>Name</Header>
            <Header>URL</Header>
            <Header>Author</Header>
            <Header>Created at</Header>
            {data &&
              data.webhooks.map((wh) => (
                <>
                  <div>{wh.name}</div>
                  <div>{wh.url}</div>
                  <div>{wh.author.email}</div>
                  <div>
                    {diffNow(wh.createdAt).split("(")[0]}
                    <small>({diffNow(wh.createdAt).split("(")[1]}</small>
                  </div>
                </>
              ))}
          </WebhookGrid>
        </Box>
      </Grid>

      <Details>
        <Box>
          <h4>API</h4>
          <p>
            The API allows you to send new requests programatically. This lets
            you automate your campaign. For example, you can send a request 3
            days after delivery of your product.
          </p>
          <p>
            To get started{" "}
            <a href={`${process.env.REACT_APP_SITE_URL}/docs`}>
              read our API documentation
            </a>
            .
          </p>
        </Box>
        <Box>
          <h4>Webhooks</h4>
          <p>
            Webhooks receive new events any time a request is sent or a response
            is received across <b>all</b> campaigns.
          </p>
          <p>
            To see the data we send{" "}
            <a href={`${process.env.REACT_APP_SITE_URL}/docs`}>
              read our API documentation
            </a>
            .
          </p>
        </Box>
      </Details>

      {modal !== "" && (
        <Modal onClose={() => setModal("")}>{modalComponent}</Modal>
      )}
    </>
  );
};

const Header = styled.div`
  background: #fafafa;
  padding: 12px 24px !important;
  font-weight: 500;
  color: #666;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 40px;
`;

const innerGridCss = css`
  display: grid;
  width: calc(100% + 48px);
  margin-left: -24px;
  font-size: 14px;

  small {
    display: block;
  }

  div {
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const APIGrid = styled.div`
  grid-template-columns: 3fr 2fr 2fr;
  ${innerGridCss}
`;

const WebhookGrid = styled.div`
  grid-template-columns: 1fr 2fr 1fr 1fr;
  ${innerGridCss}
`;

const Details = styled.div`
  margin: 72px 0 24px;
  text-align: center;
  font-size: 0.8rem;
  opacity: 0.8;

  display: grid;
  grid-template-columns: repeat(auto-fit, 340px);
  justify-content: center;
  grid-gap: 30px;

  h3 {
    text-align: center;
  }

  p {
    margin: 1rem 0;
    max-width: 340px;
    opacity: 0.7;
  }
`;

export default APIWebhook;
