import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useFormState } from "react-use-form-state";
import { useLocalStorage } from "react-use";
import Box from "src/shared/Box";
import Logo from "src/shared/Icons/Logo";
import styled from "@emotion/styled";
import Button from "src/shared/Button";
import useToast from "src/shared/Toast";
import useSelf from "src/state/self";
import { apiURL, websiteURL } from "src/utils";
import { POST_LOGIN_REDIRECT_KEY } from "src/consts";
// local
import BG from "./BG";

export default () => {
  const [loading, setLoading] = useState(false);
  const [formState, { email, password }] = useFormState();
  const history = useHistory();
  const { push } = useToast();
  const { data, refetch } = useSelf();
  const [postLoginRedirect, setPostLoginRedirect] = useLocalStorage<string>(
    POST_LOGIN_REDIRECT_KEY,
    ""
  );
  // Get the "redirect url"
  const location = useLocation();
  const to = new URLSearchParams(location.search).get("to") || "/";
  // search is the base64-encoded search string that the previous authenticated
  // page had.  As we replace the query string with "to", we store this entire
  // string base64 encoded so that we can drop this on the new page after login.
  const b64search = new URLSearchParams(location.search).get("search");
  const search = atob(b64search || "");
  const redirect = `${to}${search}`;

  const urlRef = new URLSearchParams(location.search).get("ref") || "login";

  const disabled = !formState.values.email || !formState.values.password;

  const handleSubmit = async (e: React.SyntheticEvent) => {
    setLoading(true);
    e.preventDefault();

    try {
      const result = await fetch(`${process.env.REACT_APP_API_HOST}/v1/login`, {
        method: "POST",
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(
          Object.assign({}, formState.values, { cookie: true })
        ),
      });

      setLoading(false);
      if (result.status !== 200) {
        push({ type: "error", message: "Incorrect email or password" });
        return;
      }
      refetch();
      if (to.indexOf("/login") === 0) {
        history.push("/");
        return;
      }

      history.push(redirect);
    } catch (err) {
      setLoading(false);
      push({
        type: "error",
        message: "Could not reach the Inngest API, please try again",
        duration: 50000,
      });
    }
  };

  // Redirect if already logged in
  useEffect(() => {
    if (data?.session) {
      history.push(redirect);
    }
  }, [data?.session]);

  // Fallback to set post-login redirect - see state/workspaces.tsx
  useEffect(() => {
    if (redirect && redirect.length && postLoginRedirect !== redirect) {
      setPostLoginRedirect(redirect);
    }
  }, [redirect, postLoginRedirect]);

  return (
    <BG>
      <Logo width={150} fill="#fff" />
      <Heading>Sign in to Inngest</Heading>
      <Wrapper>
        <OAuthButtons>
          <Button
            link={apiURL(
              `/v1/login/oauth/github/redirect?to=${to}&search=${b64search}`
            )}
          >
            <img src="/assets/gh-mark.png" alt="GitHub" width="20" />
            <span>
              Sign in or register with <b>GitHub</b>
            </span>
          </Button>

          <Button
            link={apiURL(
              `/v1/login/oauth/google/redirect?to=${to}&search=${b64search}`
            )}
          >
            <img src="/assets/icons/google.svg" alt="Google" width="20" />
            <span>
              Sign in or register with <b>Google</b>
            </span>
          </Button>
        </OAuthButtons>

        <Form onSubmit={handleSubmit}>
          <label>
            Email
            <input {...email("email")} placeholder="Your email" />
          </label>
          <label>
            Password
            <input {...password("password")} />
          </label>
          <Button
            type="submit"
            size="large"
            disabled={disabled}
            loading={loading}
            kind={disabled ? undefined : "primary"}
          >
            Sign in to your account
          </Button>
          <BottomLink to="/reset-password/new">Reset password</BottomLink>
        </Form>
      </Wrapper>
      <Reg
        href={websiteURL(`/sign-up?ref=${urlRef}&to=${to}&search=${b64search}`)}
      >
        Don't have an account? Register now
      </Reg>
    </BG>
  );
};

const Wrapper = styled(Box)`
  max-width: 90%;
  min-width: 380px;
  background: var(--bg-alt);

  .button {
    width: 100%;
    justify-content: center;
  }

  .button + .button {
    margin-top: 1rem;
  }

  // Short pop-up windows, e.g. Vercel integration flow
  @media (max-height: 800px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    min-width: 90%;
  }
  // mobile
  @media (max-width: 400px) {
    margin: 0 1rem;
    width: calc(100% - 2rem);
  }
`;

const OAuthButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  position: relative;

  button {
    width: 100%;
    text-align: center;
    display: block;
    margin-top: 30px;
  }

  // Stacked on vertical screens
  @media (min-height: 800px) {
    width: 100%;
    margin: 3rem 0 0;

    &:before {
      content: "";
      border-top: 1px solid var(--border-color-focus);
      margin: 0 0 3rem;
    }
    &:after {
      position: absolute;
      top: -0.7rem;
      left: 50%;
      margin-left: -29px;
      content: "or";
      background: var(--bg-alt);
      padding: 0 20px;
      color: #ccc;
    }
  }
`;

const Heading = styled.h2`
  margin: 0;
  padding: 0 0 50px;
  text-align: center;
  color: #fff;
  font-size: 1.2rem;
`;

const BottomLink = styled(Link)`
  margin: 16px 0 0;
  background: transparent;
  font-size: 12px;
  text-align: center;
  display: block;
`;

const Reg = styled.a`
  font-size: 14px;
  margin: 30px 0 20px;
  background: rgba(0, 0, 0, 0.1);
  padding: 6px 12px;
  border-radius: 3px;
`;
