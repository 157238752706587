import "react-dropdown/style.css";
import Dropdown, { Option } from "react-dropdown";
import React from "react";
import { getSupportedCodeFixes } from "typescript";

export { default as Dropdown } from "react-dropdown";

/**
 * DropdownManual allows you to control what will be the rendered value
 *
 */
class DropdownManual extends Dropdown {
  setValue(value: string, label: React.ReactNode | string) {
    // @ts-ignore
    this.fireChangeEvent({
      selected: {
        value,
        label,
      },
    });
    this.setState({ isOpen: false });
  }
}

export { DropdownManual };
