import React from "react";
import "react-toggle/style.css";
import styled from "@emotion/styled";
import ReactToggle, { ToggleProps } from "react-toggle";

type Props = ToggleProps & {
  width?: number;
  kind?: "warning" | "default";
};

const defaultWidth = 40;

export const Toggle = (props: Props) => {
  const kind = props.kind || "default";
  return (
    <Parent kind={kind}>
      <ReactToggle icons={false} {...props} />
    </Parent>
  );
};

const Parent = styled.div<Props>`
  --color-active: ${({ kind }) =>
    kind === "warning" ? "var(--color-orange)" : "var(--color-green)"};

  .react-toggle {
    vertical-align: top;
  }
  .react-toggle-track {
    width: ${(props) => props.width || defaultWidth}px;
    height: 16px;
    border: 1px solid var(--color-black);
    background-color: var(--color-gray-200);
  }
  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: var(--color-gray-200);
  }
  .react-toggle--checked .react-toggle-track {
    background-color: var(--color-active);
    box-shadow: 0px 0px 12px var(--color-active);
  }
  .react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background-color: var(--color-active);
  }
  .react-toggle-thumb {
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    background-color: var(--color-black);
    border-color: transparent;
  }
  .react-toggle--checked .react-toggle-thumb {
    left: ${(props) => (props.width || defaultWidth) - 14}px;
    border-color: transparent;
  }
  .react-toggle--focus .react-toggle-thumb,
  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: none;
  }
`;

export default Toggle;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;

  .react-toggle {
    display: block;
    margin: 0 8px;
  }
`;
