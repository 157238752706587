import { useQuery, useMutation } from "urql";

export type User = {
  id: string;
  email: string;
  name: string;
  createdAt: string;
  lastLoginAt: string;
};

export const useUsers = () => {
  return useQuery<{ account: { users: User[] } }>({
    query: `
      query AccountUsers {
        account {
          users {
            id email name createdAt lastLoginAt
          }
        }
      }
      `,
  });
};

export const useAddUser = () => {
  const [, execute] = useMutation(`
    mutation AddUser($input: NewUser!) {
      createUser(input: $input) {
        id email name createdAt lastLoginAt
      }
    }
  `);
  return execute;
};
