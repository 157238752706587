import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Box from "src/shared/Box";
import Tag from "src/shared/Tag";
import useToast from "src/shared/Toast";
import Button, { ButtonGroup } from "src/shared/Button";
import Check from "src/shared/Icons/Check";
import CardForm from "./CardForm";
import { formatNumber } from "src/utils";
import { BillingData, useUpdatePM, useUpgrade } from "./query";
import { Plan } from "./types";

type Props = {
  data: BillingData;
};

const Plans: React.FC<Props> = ({ data }) => {
  const { push } = useToast();
  const updatePMRequest = useUpdatePM();
  const upgradeRequest = useUpgrade();
  const [selectedPlan, setSelectedPlan] = useState<Plan>();
  const [saving, setSaving] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<
    "pending" | "success" | "unsubmitted"
  >("unsubmitted");

  const plans = data.plans
    .sort((a, b) => a.amount - b.amount)
    .filter((p) => {
      return p.billingPeriod === "month";
    });

  const updatePayment = async (token: { id: string; type: string }) => {
    if (saving) {
      return;
    }
    if (!token?.id) {
      console.warn(token);
      return push({ type: "error", message: "Could not create payment token" });
    }

    setSaving(true);
    setPaymentStatus("pending");
    const result = await updatePMRequest({ token: token.id });
    if (result.error) {
      setSaving(false);
      setPaymentStatus("unsubmitted");
      push({ type: "error", message: result.error.message });
      return;
    }
    setPaymentStatus("success");
    push({ type: "success", message: "Card added" });
    await upgrade();
  };

  // upgrade upgrades your plan to the selected plan within state
  const upgrade = async (plan?: Plan) => {
    if (saving) {
      return;
    }
    let upgradePlan = selectedPlan || plan;
    if (!upgradePlan) {
      setSaving(false);
      return;
    }
    setSaving(true);
    const upgradeResult = await upgradeRequest({ id: upgradePlan.id });
    setSaving(false);
    if (upgradeResult.error) {
      push({ type: "error", message: upgradeResult.error.message });
      return;
    }
    push({ type: "success", message: "Plan updated" });
  };

  return (
    <Box kind="subtleFocus" title="Change your plan">
      <PlanGrid>
        {plans.map((p) => {
          const selected = selectedPlan && selectedPlan.id === p.id;
          const current = data.account.plan.id === p.id;

          return (
            <div
              className="plan-option"
              key={`plan-${p.name}`}
              css={[
                selectedPlan && !selected && fadeOut,
                selectedPlan && selected && selectedCss,
                // Only highlight the current plan if we don't have one selected.
                !selectedPlan && current && currentCss,
              ]}
            >
              <h3>
                {p.name} <span>(${formatNumber(p.amount / 100)}/mo)</span>
              </h3>
              <TagWrapper>{current && <Tag>Current plan</Tag>}</TagWrapper>
              <ul>
                <li>
                  <Check size={18} />
                  {formatNumber(p.features.users)} users
                </li>
                <li>
                  <Check size={18} />
                  {formatNumber(p.features.workflows)} functions
                </li>
                <li>
                  <Check size={18} />
                  {formatNumber(p.features.actions)} steps <small>/month</small>
                </li>
                <li>
                  <Check size={18} />
                  {p.features.log_retention} day retention
                </li>
              </ul>
              <ButtonGroup center>
                {current ? (
                  <Button disabled>Current plan</Button>
                ) : (
                  <Button
                    kind={selected ? "dashed" : "dashed"}
                    onClick={async () => {
                      if (data.account.paymentMethods) {
                        setSelectedPlan(p);
                        await upgrade(p);
                      } else {
                        setSelectedPlan(p);
                      }
                    }}
                  >
                    {selected && "Upgrading"}
                    {!selected &&
                      (p.amount > data.account.plan.amount
                        ? "Upgrade"
                        : "Change")}
                  </Button>
                )}
              </ButtonGroup>
            </div>
          );
        })}

        <div className="plan-option">
          <h3>Need more? Contact us</h3>
          <TagWrapper />
          <ul>
            <li>
              <Check size={18} />
              Unlimited users
            </li>
            <li>
              <Check size={18} />
              Unlimited functions
            </li>
            <li>
              <Check size={18} />
              Unlimited steps
              <small>/month</small>
            </li>
            <li>
              <Check size={18} />
              Up to 90 day retention
            </li>
          </ul>
          <ButtonGroup center>
            <Button kind="dashed" link="https://www.inngest.com/contact">
              Contact us
            </Button>
          </ButtonGroup>
        </div>
      </PlanGrid>

      {!data.account.paymentMethods && (
        <CardFormWrapper css={[!selectedPlan && hiddenCardCss]}>
          <CardForm onTokenize={updatePayment} paymentStatus={paymentStatus} />
        </CardFormWrapper>
      )}
    </Box>
  );
};

const fadeOut = css`
  opacity: 0.3;
`;

const hiddenCardCss = css`
  height: 0 !important;
  padding: 0 !important;
`;

const currentCss = css`
  opacity: 1;
  border-color: var(--color-gray-300) !important;
`;

const selectedCss = css`
  background: var(--bg-alt);
  opacity: 1;
`;

const CardFormWrapper = styled.div`
  transition: all 0.3s;
  overflow: hidden;
  height: 300px;
  padding: 40px 0;
`;

const TagWrapper = styled.div`
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0 0;
`;

const PlanGrid = styled.div`
  display: grid;
  padding: 0 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;

  @media screen and (max-width: 1250px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .plan-option {
    padding: 1rem;
    transition: all 0.3s;
    border: 2px solid var(--border-color);
    border-radius: var(--border-radius);
  }

  .plan-option:hover {
    background: var(--panel-bg);
    opacity: 1;
  }

  // icon
  svg {
    color: var(--color-green);
  }

  h3 {
    text-align: center;
    span {
      opacity: 0.7;
    }
  }

  ul {
    padding: 30px 0 30px;
    list-style: none;
  }

  li {
    align-items: center;
    display: flex;
    padding: 3px 0;
    font-size: 14px;

    small {
      margin-left: 5px;
    }
  }

  svg {
    margin: 0 10px 0 0;
  }
`;

export default Plans;
