import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
// shared
import Button, { ButtonGroup } from "src/shared/Button";
import Warning from "src/shared/Warning";
import uuid from "src/utils/uuid";
// local
import getStripe from "./stripe";

type Props = {
  paymentStatus?: "unsubmitted" | "pending" | "success";
  disabled?: boolean;
  onTokenize: (token: { id: string; type: string }) => void;
  onCloseModal?: () => void;
};

const Wrapper = styled.div`
  p {
    font-size: 16px;
    margin: 0 10px 10px;
  }
`;

const Input = styled.div`
  border: 1px solid #eee;
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.05), 0 0 3px rgba(0, 0, 0, 0.03);
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 2px;
  background: #fff;

  &.StripeElement--focus {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  }
`;

const useElements = (id: string) => {
  const stripe = getStripe();
  const [card, setCard] = useState<any>(null);
  const [elements, setElements] = useState<any>(null);

  useEffect(() => {
    if (!stripe || card !== null) {
      return;
    }

    const elements =
      stripe &&
      stripe.elements({
        fonts: [{ cssSrc: "https://rsms.me/inter/inter-ui.css" }],
      });

    setElements(elements);

    const newCard =
      elements &&
      elements.create("card", {
        style: {
          base: {
            fontWeight: 500,
            fontFamily: "Inter UI, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",

            "::placeholder": {
              color: "#CFD7DF",
            },
          },
          invalid: {
            color: "#E25950",
          },
        },
      });

    if (document.getElementById(id) !== null && newCard) {
      newCard.mount("#" + id);
      setCard(newCard);
    }
  }, [stripe]);

  return [stripe, elements, card];
};

const CardForm = React.memo((props: Props) => {
  // Store a local state variable containing the ID for this component's lifecycle.
  // We may have > 1 card form on the page (eg. hidden within modals), therefore each
  // instance of this component needs its own element.
  const [el] = useState(`stripe-card-${uuid()}`);
  const [loading, setLoading] = useState(false);
  const [stripe, elements, card] = useElements(el);
  const [error, setError] = useState("");

  const onSubmit = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const result = await (stripe as any).createToken(
      (elements as any).getElement("card")
    );
    if (result.error) {
      setLoading(false);
      return setError(result.error.message);
    } else {
      setError("");
    }
    // Must happen after tokenizing, otherwise react will re-render the
    // component and stripe will complain
    props.onTokenize(result.token);
    setLoading(false);
  };

  return (
    <Wrapper>
      <p>Enter your card details:</p>
      {!stripe ? (
        <Warning>
          Could not load Stripe - Please allow third party scripts like Stripe
          to be loaded to check out
        </Warning>
      ) : (
        <>
          <Input id={el} />
          {!!error && <Warning>{error}</Warning>}
          <ButtonGroup center style={{ marginTop: "30px" }}>
            <Button
              kind={props.paymentStatus === "success" ? "green" : "primary"}
              onClick={onSubmit}
              loading={loading}
            >
              {props.paymentStatus === "success"
                ? "Card added successfully!"
                : props.paymentStatus === "pending"
                ? "Updating payment method..."
                : "Confirm"}
            </Button>
            {!!props.onCloseModal && props.paymentStatus === "success" && (
              <Button onClick={props.onCloseModal}>Close</Button>
            )}
          </ButtonGroup>
        </>
      )}
    </Wrapper>
  );
});

export default CardForm;
